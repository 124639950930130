/**
 * Format milliseconds to a localized string like "1,234 h 56 min".
 */
export function formatDuration(ms: number, locale: string): string {
  const totalMinutes = Math.round(ms / 60000)
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  if (hours && minutes) {
    return `${localizeNumber(hours, locale)} h ${minutes} min`
  }
  if (hours) {
    return `${localizeNumber(hours, locale)} h`
  }
  if (minutes) {
    return `${minutes} min`
  }
  return '< 1 min'
}

/**
 * Format meters to a localized string like "1,234 m", optionally in ft.
 */
export function formatLength(m: number, locale: string, imperial: boolean, isRounded?: boolean): string {
  const distance = Math.round(imperial ? m * 3.28084 : m)
  return `${localizeNumber((isRounded ? roundToNearest(distance) : distance), locale)} ${imperial ? 'ft' : 'm'}`
}

/**
 * Format meters to a localized string like "1,234.5 km", optionally in mi.
 */
export function formatLargeLength(m: number, locale: string, imperial: boolean): string {
  const distance = Math.round(imperial ? m / 160.934 : m / 100) / 10
  return `${localizeNumber(distance, locale)} ${imperial ? 'mi' : 'km'}`
}

/**
 * Format km/h to a localized string like "23,5 km/h", optionally in mph.
 */
export function formatSpeed(ms: number, locale: string, imperial: boolean): string {
  const speed = Math.round((imperial ? ms * 2.23694 : ms * 3.6) * 10) / 10
  return `${localizeNumber(speed, locale)} ${imperial ? 'mph' : 'km/h'}`
}

export function localizeNumber(number: number, locale: string): string {
  return new Intl.NumberFormat(locale).format(number)
}

export const roundToNearest = (roundedNum: number) => {
  if (roundedNum <= 0) {
    return 0
  }
  if (roundedNum < 5) {
    return Math.round(roundedNum)
  }
  if (roundedNum < 10) {
    return Math.round(roundedNum / 5) * 5
  }
  if (roundedNum < 100) {
    return Math.round(roundedNum / 10) * 10
  }
  if (roundedNum < 1000) {
    return Math.round(roundedNum / 50) * 50
  }
  return Math.round(roundedNum / 100) * 100
}
