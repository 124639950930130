import { RoutePoiEntity } from '@web/shared/feature-api'
import { MapMarkerAccommodation, MapMarkerPhoto, MapMarkerPoi, MapMarkerRepair, MapMarkerWater } from '@web/shared/feature-map'
import { MapboxEvent } from 'mapbox-gl'
import { useRoutePois } from '../route-pois-context'

interface RoutePoiMapMarkerProps {
  routePoi: RoutePoiEntity
  onSelect?: (routePoi: RoutePoiEntity) => void
  interactive?: boolean
}

export const RoutePoiMapMarker = ({
  routePoi,
  onSelect,
  interactive = true,
}: RoutePoiMapMarkerProps) => {
  const { selectedRoutePoi, onRoutePoiSelectionChange } = useRoutePois()
  const isSelected = routePoi.id === selectedRoutePoi?.id

  const props = {
    longitude: routePoi.lng,
    latitude: routePoi.lat,
    size: isSelected ? 'large' : 'default',
    onClick: !isSelected && interactive ? (e: MapboxEvent<MouseEvent>) => {
      e.originalEvent.stopPropagation()
      onRoutePoiSelectionChange(routePoi.id)
      if (onSelect) {
        onSelect(routePoi)
      }
    } : undefined,
  }

  switch (routePoi.type) {
    case 'water': return <MapMarkerWater {...props} />
    case 'repair': return <MapMarkerRepair {...props} />
    case 'hotel': return <MapMarkerAccommodation {...props} />
    case 'photo': return <MapMarkerPhoto {...props} />
  }
  return <MapMarkerPoi {...props} />
}
