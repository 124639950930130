/**
 * Try to find provided param in URL.
 */
export function getFromUrl(param: string): string|undefined {
  const url = new URL(window.location.toString())
  const val = url.searchParams.get(param)
  return val || undefined
}

/**
 * Remove a param from URL.
 */
export function removeFromUrl(param: string): void {
  const url = new URL(window.location.toString())
  url.searchParams.delete(param)
  window.history.replaceState(null, '', url)
}

/**
 * Try to find provided param in URL and also remove it if found.
 */
export function extractFromUrl(param: string): string|undefined {
  try {
    const value = getFromUrl(param)
    if (value) {
      removeFromUrl(param)
    }
    return value
  } catch {
    return undefined
  }
}
