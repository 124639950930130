import { useEffect, useState } from 'react'

/**
 * Updates the document title to the given string. When unmounting, it resets to the previous title.
 */
export function useTitle(viewTitle: string) {
  const [originalTitle] = useState<string>(document.title)

  useEffect(() => {
    document.title = viewTitle
    return () => {
      document.title = originalTitle
    }
  }, [originalTitle, viewTitle])
}
