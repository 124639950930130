import { Link as MuiLink, LinkBaseProps } from '@mui/material'
import styles from './Link.module.scss'
import clsx from 'clsx'

export interface LinkProps extends LinkBaseProps {
  button?: boolean
  small?: boolean
  secondary?: boolean
  onColor?: boolean
}

export const Link = ({ button, small, secondary, onColor, ...props }: LinkProps) => {
  return (
    <MuiLink
      className={clsx(styles['anchor'], {
        [styles['secondary']]: secondary,
        [styles['small']]: small,
        [styles['on-color']]: onColor,
      })}
      component={button ? 'button' : 'a'}
      {...props}
    />
  )
}
