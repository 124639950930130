import { useIntl, IntlShape } from 'react-intl'
import { AvailableLocalesType } from './types'

export interface UseLocale {
  intl: IntlShape,
  language: AvailableLocalesType,
}

/**
 * This is preferred source of truth for getting current language.
 * Because translated texts requre useIntl() -> formatMessage() - provided by react-intl wrapper,
 * so it's more consistent if we read language from the same hook.
 */
export function useLocale(): UseLocale {
  const intl = useIntl()
  return {
    intl,
    language: intl.locale as AvailableLocalesType,
  }
}
