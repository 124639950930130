import { ElevationData } from './types'
import { ELEVATION_TOLERANCE } from './settings'

/**
 * Calculate cumulative ascent along a given elevation curve.
 */
export function calculateAscent(elevationData: ElevationData[]) {
  let ascent = 0
  let previousHeight = 0

  for (let i = 0; i < elevationData.length; i++) {
    const elevation = elevationData[i].elevation
    if (i === 0) {
      previousHeight = elevation
      continue
    }

    // We update previousHeight in two cases:
    // 1) route goes up and difference is more than threshold
    // 2) route goes down and a new value is lower than previous height on amount bigger than threshold
    const delta = elevation - previousHeight
    if (delta > ELEVATION_TOLERANCE) {
      ascent += delta
      previousHeight = elevation
    } else if (previousHeight - elevation > ELEVATION_TOLERANCE) {
      previousHeight = elevation
    }
  }
  return ascent
}

/**
 * Calculate cumulative descent along a given elevation curve.
 */
export function calculateDescent(elevationData: ElevationData[]) {
  let descent = 0
  let previousHeight = 0

  for (let i = 0; i < elevationData.length; i++) {
    const elevation = elevationData[i].elevation
    if (i === 0) {
      previousHeight = elevation
      continue
    }

    // We update previousHeight in two cases:
    // 1) route goes down and difference is more than threshold
    // 2) route goes up and a new value is higher than previous height on amount bigger than threshold
    const delta = previousHeight - elevation
    if (delta > ELEVATION_TOLERANCE) {
      descent += delta
      previousHeight = elevation
    } else if (elevation - previousHeight > ELEVATION_TOLERANCE) {
      previousHeight = elevation
    }
  }
  return descent
}
