import { useCallback, useEffect } from 'react'
import { useMap } from 'react-map-gl'

export function useMapImage(url: string, name: string) {
  const mapRef = useMap()
  const currentMap = mapRef.current

  const addImage = useCallback(() => {
    if (currentMap) {
      const map = currentMap.getMap()

      map.loadImage(url, (error, image) => {
        if (error) throw error
        if (image && !map.hasImage(name)) {
          map.addImage(name, image)
        }
      })
    }
  }, [currentMap, name, url])

  useEffect(() => {
    addImage()
    currentMap?.on('styledata', addImage)

    return () => {
      currentMap?.off('styledata', addImage)
    }
  }, [addImage, currentMap])
}
