import { Skeleton, Typography } from '@mui/material'
import { RouteEntity, formatLength, formatLargeLength } from '@web/shared/feature-api'
import { ArrowAscent, ArrowDescent, ArrowDistance } from '@web/shared/ui-components'
import { useLocale } from '@web/shared/utils-intl'
import { useWidgetContext } from '@web/widgets/utils-basics'
import { useRouteWidgetParams } from '../context'

import styles from './route-widget-stats.module.scss'

interface RouteStatsProps {
  route: RouteEntity
}

const RouteStats = ({
  route,
}: RouteStatsProps) => {
  const { language } = useLocale()
  const { unitPreference } = useRouteWidgetParams()

  return (
    <Typography color='textSecondary' className={styles['stats']}>
      <ArrowDistance className={styles['icon']} /> {formatLargeLength(route.distance, language, unitPreference === 'imperial')}
      <ArrowAscent className={styles['icon']} /> {formatLength(route.ascent, language, unitPreference === 'imperial')}
      <ArrowDescent className={styles['icon']} /> {formatLength(route.descent, language, unitPreference === 'imperial')}
    </Typography>
  )
}

interface RouteWidgetStatsProps {
  route?: RouteEntity
}

export const RouteWidgetStats = ({
  route,
}: RouteWidgetStatsProps) => {
  const { isMedium } = useWidgetContext()

  return isMedium
    ? route
      ? <RouteStats route={route} />
      : <Skeleton width='15rem' data-testid='route-widget-stats-skeleton' />
    : null
}
