import { WIDGETS_BASE_ROUTE } from './paths'

const WEBSITE_BASE = process.env['NX_WEBSITE_BASE_URL'] || 'https://www.bikemap.net'
const WIDGETS_BASE = process.env['NX_WIDGETS_BASE_URL'] || 'https://widgets.bikemap.net'

type WebsiteLanguage = 'en'|'de'
type UnitPreference = 'metric'|'imperial'

type TrackingParameters = {
  utmMedium: string
  utmCampaign: string
}

export interface BaseUrlArgs {
  language: WebsiteLanguage
  unitPreference: UnitPreference
  cookieConsentStatistics: boolean | null
  trackingParameters?: TrackingParameters
}

interface LanguageUrlArgs {
  language: WebsiteLanguage
}

interface UserProfileUrlArgs extends BaseUrlArgs {
  slug: string
}

interface RouteDetailUrlArgs extends BaseUrlArgs {
  routeId: number
}

interface RouteShareUrlArgs {
  routeId: number
  trackingParameters: TrackingParameters
}

interface RouteEditorUrlArgs extends BaseUrlArgs {
  routeId: number
}

interface RouteCopyUrlArgs extends BaseUrlArgs {
  copyRouteId: number
}

interface LoginUrlArgs extends BaseUrlArgs {
  path?: string
}

interface CheckoutUrlArgs extends BaseUrlArgs {
  path?: string
}

interface RouteCollectionUrlArgs extends BaseUrlArgs {
  routeCollectionId: number
}

/**
 * Concatenates the full Website URL consisting of origin, path and parameters. Path must have a
 * trailing `/`. To append parameters, start with `&`.
 */
function formatWebsiteUrl(path: string, args: BaseUrlArgs) {
  const { language, unitPreference, cookieConsentStatistics, trackingParameters } = args

  const params = new URLSearchParams({
    unit: unitPreference,
  })

  if (cookieConsentStatistics !== null) {
    params.set('cookieConsentStatistics', cookieConsentStatistics ? '1' : '0')
  }

  if (trackingParameters) {
    addTrackingParameters(trackingParameters, params)
  }

  const paramsString = params.toString()
  return WEBSITE_BASE + `/${language}/${path}${paramsString && '?' + paramsString}`
}

function addTrackingParameters(
  values: TrackingParameters,
  params: URLSearchParams = new URLSearchParams
): URLSearchParams {
  params.set('utm_source', 'website')
  params.set('utm_medium', values.utmMedium)
  params.set('utm_campaign', values.utmCampaign)
  return params
}

export const statelessHomeUrl = WEBSITE_BASE + '/'

export const statelessImprintUrl = WEBSITE_BASE + '/imprint/'

export function getUserGarminUrl({
  slug,
  ...baseArgs
}: UserProfileUrlArgs): string {
  return formatWebsiteUrl(`u/${slug}/settings/garmin/`, baseArgs)
}

export function getRouteDetailUrl({
  routeId,
  ...baseArgs
}: RouteDetailUrlArgs): string {
  return formatWebsiteUrl(`r/${routeId}/`, baseArgs)
}

export function getRouteShareUrl({
  routeId,
  trackingParameters,
}: RouteShareUrlArgs): string {
  const paramsString = addTrackingParameters(trackingParameters).toString()
  return WEBSITE_BASE + `/r/${routeId}/?${paramsString}`
}

export function getRouteEditorUrl({
  routeId,
  ...baseArgs
}: RouteEditorUrlArgs): string {
  return formatWebsiteUrl(`r/${routeId}/edit/`, baseArgs)
}

export function getRouteCopyUrl({
  copyRouteId,
  ...baseArgs
}: RouteCopyUrlArgs): string {
  return formatWebsiteUrl('r/create/', baseArgs) + `&copy=${copyRouteId}`
}

export function getHomeUrl(baseArgs: BaseUrlArgs): string {
  return formatWebsiteUrl('', baseArgs)
}

export function getPremiumUrl(baseArgs: BaseUrlArgs): string {
  return formatWebsiteUrl('premium/', baseArgs)
}

export function getLoginUrl({
  path,
  ...baseArgs
}: LoginUrlArgs): string {
  const nextPath = path ? `&next=${encodeURIComponent('/web-app' + path)}` : ''
  return formatWebsiteUrl('login/', baseArgs) + nextPath
}

export function getUserProfileUrl({
  slug,
  ...baseArgs
}: UserProfileUrlArgs): string {
  return formatWebsiteUrl(`u/${slug}/`, baseArgs)
}

export function getProfileSettingsUrl({
  slug,
  ...baseArgs
}: UserProfileUrlArgs): string {
  return formatWebsiteUrl(`u/${slug}/settings/`, baseArgs)
}

export function getFavoriteRoutesUrl({
  slug,
  ...baseArgs
}: UserProfileUrlArgs): string {
  return formatWebsiteUrl(`u/${slug}/routes/favorites/`, baseArgs)
}

export function getSearchUrl(baseArgs: BaseUrlArgs): string {
  return formatWebsiteUrl('search/', baseArgs)
}

export function getPrivacyPolicyUrl(baseArgs: BaseUrlArgs): string {
  return formatWebsiteUrl('dataprivacy/', baseArgs)
}

export function getHelpCenterUrl({
  language,
}: LanguageUrlArgs): string {
  return `https://help.bikemap.net/hc/${language}`
}

export function getCookieDeclarationUrl(baseArgs: BaseUrlArgs): string {
  return formatWebsiteUrl('cookies/', baseArgs)
}

export function getImprintUrl(baseArgs: BaseUrlArgs): string {
  return formatWebsiteUrl('imprint/', baseArgs)
}

export function getCheckoutUrl({
  path,
  ...baseArgs
}: CheckoutUrlArgs): string {
  const params = path ? `&premium_next=${encodeURIComponent('/web-app' + path)}` : ''
  return formatWebsiteUrl('payment/checkout/', baseArgs) + params
}

export function getRouteCollectionUrl({
  routeCollectionId,
  ...baseArgs
}: RouteCollectionUrlArgs): string {
  return formatWebsiteUrl(`c/${routeCollectionId}/`, baseArgs)
}

interface LogoutUrlArgs extends BaseUrlArgs {
  path?: string
}

export function getLogoutUrl({
  path,
  ...baseArgs
}: LogoutUrlArgs): string {
  const nextPath = path ? `&next=${encodeURIComponent('/web-app' + path)}` : ''
  return formatWebsiteUrl('logout/', baseArgs) + nextPath
}

type RouteWidgetUrlArgs = {
  routeId: number
  unitPreference: UnitPreference
  extended: boolean
  distanceMarkers: boolean
}

export function getRouteWidgetUrl({
  routeId,
  unitPreference,
  extended,
  distanceMarkers,
}: RouteWidgetUrlArgs): string {
  const params = new URLSearchParams({
    unit: unitPreference,
  })
  if (extended) {
    params.append('extended', '1')
  }
  if (distanceMarkers) {
    params.append('distanceMarkers', '1')
  }
  return `${WIDGETS_BASE}${WIDGETS_BASE_ROUTE.replace(':routeId', routeId.toString())}?${params.toString()}`
}
