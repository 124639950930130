import { useEffect } from 'react'
import { useLocale } from './use-locale'

/**
 * Makes sure the `lang` attribute of the `<html>` node is in sync with the current language.
 */
export const useSyncLangAttribute = () => {
  const { language } = useLocale()

  useEffect(() => {
    if (document.documentElement.lang !== language) {
      document.documentElement.lang = language
    }
  }, [language])
}
