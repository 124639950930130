import { Typography } from '@mui/material'
import { useLocale } from '@web/shared/utils-intl'
import { WidgetContainer } from '../container'
import { BikemapLogo } from '@web/shared/ui-components'

import styles from './widget-fallback-view.module.scss'

export const WidgetFallbackView = () => {
  const { intl } = useLocale()

  const title = intl.formatMessage({
    id: 'widget_fallback_title',
    defaultMessage: 'Widget not found',
  })

  return (
    <WidgetContainer>
      <div className={styles['container']}>
        <BikemapLogo size='10vw' />
        <Typography fontSize='3vw'>{title}</Typography>
      </div>
    </WidgetContainer>
  )
}
