import { LineString, MultiLineString } from 'geojson'
import { LngLat, GeometryCoordinates } from '../types'
import { decodePath2d } from './decode-path'

export function geometryCoordinatesToLngLat(coordinates: GeometryCoordinates): LngLat {
  return {
    lng: coordinates[0],
    lat: coordinates[1],
  }
}

export function lngLatToGeometryCoordinates(point: LngLat): [number, number] {
  return [point.lng, point.lat]
}

/**
 * Returns an array of the LngLats that could be parsed from a given polyline string.
 */
export function decodeLngLatsPath(encoded: string): LngLat[] {
  return decodePath2d(encoded).map(geometryCoordinatesToLngLat)
}

/**
 * Convert geometry to LineString taking the first route segment.
 */
export function convertToLineString(geometry: MultiLineString): LineString {
  return {
    ...geometry,
    type: 'LineString',
    // Take first route segment
    coordinates: geometry.coordinates[0],
  }
}

/**
 * Checks if received geometry has coordinates.
 */
export function isValidGeometry(geometry: MultiLineString | null): boolean {
  return !!(geometry && geometry.coordinates && geometry.coordinates[0] && geometry.coordinates[0].length > 0)
}
