import { LineString } from 'geojson'
import { SelectionMapRouteSection } from './selection-map-route-section'
import { ElevationCurveDistanceMarkers } from './elevation-curve-distance-markers'
import { ElevationMapMarkerPoint } from './elevation-map-marker-point'
import { useElevationCurveContext } from '../context'

interface ElevationCurveMapFeaturesProps {
  mapRouteId: string
  geometry: LineString
  distance: number
  areDistanceMarkersPermanent?: boolean
}

export const ElevationCurveMapFeatures = ({
  mapRouteId,
  geometry,
  distance,
  areDistanceMarkersPermanent,
}: ElevationCurveMapFeaturesProps) => {
  const { unitPreference, elevationPointIndex, selectionIndexes } = useElevationCurveContext()

  return (
    <>
      <ElevationCurveDistanceMarkers
        mapRouteId={mapRouteId}
        geometry={geometry}
        distance={distance}
        unitPreference={unitPreference}
        visible={
          !selectionIndexes &&
          (areDistanceMarkersPermanent || elevationPointIndex !== undefined)
        }
      />
      {selectionIndexes && (
        <SelectionMapRouteSection mapRouteId={mapRouteId} geometry={geometry} selectionIndexes={selectionIndexes} />
      )}
      <ElevationMapMarkerPoint geometry={geometry} />
    </>
  )
}
