import React, { createContext, useContext, useMemo, useState } from 'react'
import { logError } from '@web/shared/utils-error-handling'
import { ElevationData, SelectionIndexes, UnitPreference } from '../types'
import { LineString } from 'geojson'
import { calculateCheapElevationCurve, calculateElevationCurve } from './elevation-curve-calculation'
import { CHEAP_CALCULATION_THRESHOLD } from '../settings'

type ElevationCurveContextValues = {
  elevation?: ElevationData[] | null
  unitPreference: UnitPreference
  elevationPointIndex?: number
  selectionIndexes?: SelectionIndexes
  onElevationPointIndexChange: (value?: number) => void
  onSelectionIndexesChange: (value?: SelectionIndexes) => void
}

const ElevationCurveContext = createContext<ElevationCurveContextValues>({
  elevation: undefined,
  unitPreference: 'metric',
  elevationPointIndex: undefined,
  selectionIndexes: undefined,
  onElevationPointIndexChange: () => logError('Elevation curve context handlers not yet available'),
  onSelectionIndexesChange: () => logError('Elevation curve context handlers not yet available'),
})

interface ElevationCurveProviderProps {
  geometry?: LineString
  distance?: number
  unitPreference: UnitPreference
  children: React.ReactNode
}

/**
 * Provides context for the elevation curve and the corresponding map.
 */
export const ElevationCurveProvider = ({
  geometry,
  distance,
  unitPreference,
  children,
}: ElevationCurveProviderProps) => {
  const [elevationPointIndex, setElevationPointIndex] = useState<number | undefined>()
  const [selectionIndexes, setSelectionIndexes] = useState<SelectionIndexes | undefined>()

  const elevation = useMemo<undefined | ElevationData[] | null>(() => {
    if (!geometry || !distance) return undefined

    try {
      if (geometry.coordinates.length < CHEAP_CALCULATION_THRESHOLD) {
        return calculateElevationCurve(geometry, distance, unitPreference)
      }
      return calculateCheapElevationCurve(geometry, distance, unitPreference)
    } catch (error) {
      logError('Elevation Curve Error', error)
    }
    return null
  }, [distance, geometry, unitPreference])

  return (
    <ElevationCurveContext.Provider value={{
      elevation,
      unitPreference,
      elevationPointIndex,
      selectionIndexes,
      onElevationPointIndexChange: setElevationPointIndex,
      onSelectionIndexesChange: setSelectionIndexes,
    }}>
      {children}
    </ElevationCurveContext.Provider>
  )
}

/**
 * Get context for the elevation curve.
 */
export const useElevationCurveContext = (): ElevationCurveContextValues => {
  const context = useContext(ElevationCurveContext)
  if (!context) {
    logError('useElevationCurveContext must be used inside ElevationCurveProvider')
  }
  return context
}
