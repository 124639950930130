import { logError } from '@web/shared/utils-error-handling'
import { DataLayerObject, GtagOptions } from '../types'

declare global {
  interface Window {
    dataLayer?: object[]
    gtag?: (...args: (string | GtagOptions)[]) => void
  }
}

export function pushToDataLayer(obj: DataLayerObject) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(obj)
}

export function gtag(...args: (string | GtagOptions)[]) {
  if (window.gtag) {
    window.gtag(...args)
  } else {
    logError('gtag function undefined')
  }
}
