import React, { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path d="M11.095 3.423a1 1 0 0 1 1.81 0l3.188 6.776a1 1 0 0 1-.905 1.426H8.812a1 1 0 0 1-.904-1.426l3.187-6.776Z"/>
    <path opacity="0.5" d="M11.095 21.576a1 1 0 0 0 1.81 0l3.188-6.775a1 1 0 0 0-.905-1.426H8.812a1 1 0 0 0-.904 1.426l3.187 6.775Z"/>
  </svg>
)

export default svg
