export const ROUTE_POI_TYPES = ['sight', 'photo', 'repair', 'water', 'hotel'] as const

export type RoutePoiType = typeof ROUTE_POI_TYPES[number]

export type RoutePoiEntity = {
  id: number
  routeId: number
  lng: number
  lat: number
  type: RoutePoiType
  description?: string
  image?: string
}

export const dummyRoutePoiEntity: RoutePoiEntity = {
  id: 531,
  routeId: 100,
  lng: 17.111,
  lat: 49.114,
  type: 'photo',
  description: 'Some description...',
  image: '/poi-image1',
}
