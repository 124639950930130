import { Components } from '@mui/material'
import { colors } from '@web/shared/ui-design-system'

export const MuiSwitch: Components['MuiSwitch'] = {
  styleOverrides: {
    switchBase: {
      color: colors.actionColor.inactive,
    },
    track: {
      opacity: 1,
      background: colors.onNeutral.tertiary,
    },
  },
}
