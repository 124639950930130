import { RouteEntity } from '@web/shared/feature-api'
import { ElevationCurveProvider } from '@web/shared/feature-elevation-curve'
import { useWidgetContext } from '@web/widgets/utils-basics'
import { RouteWidgetContent, RouteWidgetLayout, RouteWidgetMapContainer } from './route-widget-layout'
import { RouteWidgetMap } from '../components/route-widget-map'
import { RouteWidgetTitle } from '../components/route-widget-title'
import { RouteWidgetStats } from '../components/route-widget-stats'
import { RouteWidgetDescription } from '../components/route-widget-description'
import { useRouteWidgetParams } from '../context'
import { RouteWidgetElevationCurve } from '../components/route-widget-elevation-curve'
import { RoutePoisProvider } from '@web/shared/feature-route-pois'

interface PublicRouteWidgetProps {
  route?: RouteEntity
}

export const PublicRouteWidget = ({
  route,
}: PublicRouteWidgetProps) => {
  const { unitPreference, isExtended } = useRouteWidgetParams()
  const { isLarge } = useWidgetContext()

  return (
    <ElevationCurveProvider
      geometry={route?.geometry}
      distance={route?.distance}
      unitPreference={unitPreference}
    >
      <RoutePoisProvider routeId={route?.id}>
        <RouteWidgetLayout>
          <RouteWidgetMapContainer>
            <RouteWidgetMap route={route} />
          </RouteWidgetMapContainer>
          <RouteWidgetContent columns={isLarge && isExtended}>
            <RouteWidgetTitle route={route} />
            <RouteWidgetStats route={route} />
            {isExtended && <RouteWidgetDescription route={route} />}
            {isExtended && <RouteWidgetElevationCurve />}
          </RouteWidgetContent>
        </RouteWidgetLayout>
      </RoutePoisProvider>
    </ElevationCurveProvider>
  )
}
