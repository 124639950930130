import { useMediaQuery } from '@mui/material'

export type WidgetSize = {
  isMedium: boolean
  isLarge: boolean
}

export const useWidgetSize = (): WidgetSize => {
  const isMedium = useMediaQuery('(min-width: 320px) and (min-height: 380px)', { noSsr: true })
  const isLarge = useMediaQuery('(min-width: 640px) and (min-height: 480px)', { noSsr: true })

  return { isMedium, isLarge }
}
