import { GlobalStyles } from '@mui/material'
import { StylesMode } from './helpers/get-as-custom-properties'
import { GlobalStylesOptions, getGlobalStyles } from './helpers/get-global-styles'

interface GlobalThemeStylesProps extends GlobalStylesOptions {
  mode?: StylesMode
}

export const GlobalThemeStyles = ({
  mode,
  ...options
}: GlobalThemeStylesProps) => (
  <GlobalStyles styles={getGlobalStyles(mode, options)} />
)
