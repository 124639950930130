import { useMemo } from 'react'
import { useLocale } from '@web/shared/utils-intl'
import { getRouteDetailUrl } from '@web/shared/utils-navigation'
import { useRouteWidgetParams } from '../context'

export const useRouteDetailUrl = (routeId: number, utmCampaign: string): string => {
  const { language } = useLocale()
  const { unitPreference } = useRouteWidgetParams()

  return useMemo(() => getRouteDetailUrl({
    language,
    unitPreference,
    cookieConsentStatistics: null,
    routeId,
    trackingParameters: {
      utmMedium: 'route_widget',
      utmCampaign,
    },
  }), [language, routeId, unitPreference, utmCampaign])
}
