export type ColorValue = string | {
  [key: string]: ColorValue
}

type ColorDefinitionsBaseType = {
  [key: string]: {
    [key: string]: ColorValue
  }
}

/**
 * Definition of the theme color palette for both dark and light mode.
 */
export interface ColorDefinitions extends ColorDefinitionsBaseType {

  /**
   * Used for backgrounds.
   */
  neutral: {
    primary: string
    secondary: string
    tertiary: string
    quartery: string
  }

  /**
   * Used for text on neutral backgrounds.
   */
  onNeutral: {
    primary: string
    secondary: string
    tertiary: string
  }

  /**
   * Used for colored backgrounds.
   */
  coloredBG: {
    secondaryBlue500: string
    secondaryBlue700: string
  }

  /**
   * Used for text on colored backgrounds.
   */
  onColor: {
    primary: string
    secondary: string
    tertiary: string
  }

  /**
   * Used for colored backgrounds or as text on neutral backgrounds with the onNeutral color variants.
   */
  actionColor: {
    primary: string
    secondary: string
    tertiary: string
    inactive: string
    onNeutral: {
      primary: string
      secondary: string
      tertiary: string
      inactive: string
    }
  }

  /**
   * Used in the same way as actionColor but only to add specific highlights in the UI. Use wisely.
   */
  accentColor: {
    primary: string
    secondary: string
    tertiary: string
    inactive: string
    onNeutral: {
      primary: string
      secondary: string
      tertiary: string
      inactive: string
    }
  }

  /**
   * Used in the same way as actionColor but only to highlight destructive actions, warnings or errors.
   */
  dangerColor: {
    primary: string
    secondary: string
    tertiary: string
    inactive: string
    onNeutral: {
      primary: string
      secondary: string
      tertiary: string
      inactive: string
    }
  }

  /**
   * Special color style for our premium locks.
   */
  premiumComp: {
    premiumIndicator: string
  }

  /**
   * Color styles for our polyline states.
   */
  polylineComp: {
    foreground: string
    background: string
    tracking: {
      foreground: string
      background: string
    }
    alternate: {
      foreground: string
      background: string
    }
    inactive: {
      foreground: string
      background: string
    }
  }

  /**
   * Color styles for our notification component.
   */
  notificationComp: {
    info: string
    success: string
    warning: string
    danger: string
  }

}

export const lightColors: ColorDefinitions = {
  neutral: {
    primary: '#FFFFFF',
    secondary: '#E6ECF2',
    tertiary: '#CDDAE6',
    quartery: '#9BB5CC',
  },
  onNeutral: {
    primary: '#04305A',
    secondary: '#064580',
    tertiary: '#83A2C0',
  },
  coloredBG: {
    secondaryBlue500: '#04294D',
    secondaryBlue700: '#021C33',
  },
  onColor: {
    primary: '#FFFFFF',
    secondary: '#FFFFFFCC',
    tertiary: '#FFFFFF99',
  },
  actionColor: {
    primary: '#0873E7',
    secondary: '#065CB9',
    tertiary: '#05458B',
    inactive: '#CDDAE6',
    onNeutral: {
      primary: '#0873E7',
      secondary: '#065CB9',
      tertiary: '#05458B',
      inactive: '#83A2C0',
    },
  },
  accentColor: {
    primary: '#34C759',
    secondary: '#2A9F47',
    tertiary: '#1F7735',
    inactive: '#CDDAE6',
    onNeutral: {
      primary: '#34C759',
      secondary: '#2A9F47',
      tertiary: '#1F7735',
      inactive: '#83A2C0',
    },
  },
  dangerColor: {
    primary: '#FF3B30',
    secondary: '#CC2F26',
    tertiary: '#99231D',
    inactive: '#CDDAE6',
    onNeutral: {
      primary: '#FF3B30',
      secondary: '#CC2F26',
      tertiary: '#99231D',
      inactive: '#83A2C0',
    },
  },
  premiumComp: {
    premiumIndicator: '#05458B',
  },
  polylineComp: {
    foreground: '#0873E7',
    background: '#065CB9',
    tracking: {
      foreground: '#34C759',
      background: '#2A9F47',
    },
    alternate: {
      foreground: '#CBE9FF',
      background: '#53B5FF',
    },
    inactive: {
      foreground: '#CDDAE6',
      background: '#83A2C0',
    },
  },
  notificationComp: {
    info: '#398FEC',
    success: '#5DD27A',
    warning: '#FFAA33',
    danger: '#FF6259',
  },
}

export const darkColors: ColorDefinitions = {
  neutral: {
    primary: '#01070D',
    secondary: '#02172E',
    tertiary: '#04294D',
    quartery: '#010E1A',
  },
  onNeutral: {
    primary: '#FFFFFF',
    secondary: '#FFFFFFCC',
    tertiary: '#FFFFFF99',
  },
  coloredBG: {
    secondaryBlue500: '#04294D',
    secondaryBlue700: '#021C33',
  },
  onColor: {
    primary: '#FFFFFF',
    secondary: '#FFFFFFCC',
    tertiary: '#FFFFFF99',
  },
  actionColor: {
    primary: '#0873E7',
    secondary: '#065CB9',
    tertiary: '#05458B',
    inactive: '#04294D',
    onNeutral: {
      primary: '#84B9F3',
      secondary: '#529DEE',
      tertiary: '#2181E9',
      inactive: '#FFFFFF99',
    },
  },
  accentColor: {
    primary: '#34C759',
    secondary: '#2A9F47',
    tertiary: '#1F7735',
    inactive: '#04294D',
    onNeutral: {
      primary: '#9AE3AC',
      secondary: '#71D88B',
      tertiary: '#48CD6A',
      inactive: '#FFFFFF99',
    },
  },
  dangerColor: {
    primary: '#FF3B30',
    secondary: '#CC2F26',
    tertiary: '#99231D',
    inactive: '#04294D',
    onNeutral: {
      primary: '#FF9D98',
      secondary: '#FF766E',
      tertiary: '#FF4F45',
      inactive: '#FFFFFF99',
    },
  },
  premiumComp: {
    premiumIndicator: '#CEE3FA',
  },
  polylineComp: {
    foreground: '#529DEE',
    background: '#0873E7',
    tracking: {
      foreground: '#71D88B',
      background: '#34C759',
    },
    alternate: {
      foreground: '#CBE9FF',
      background: '#53B5FF',
    },
    inactive: {
      foreground: '#CDDAE6',
      background: '#83A2C0',
    },
  },
  notificationComp: {
    info: '#05458B',
    success: '#1F7735',
    warning: '#995900',
    danger: '#99231D',
  },
}

/**
 * Ready to respect dark mode preference in the future.
 */
export const colors = lightColors
