import { RequestParamsType } from './types'

/**
 * Replace variable parts of URL (e.g. /path/with/:id/).
 */
export function replaceParams(url: string, params?: RequestParamsType): string {
  for (const key in params) {
    url = url.replace(':' + key, String(params[key]))
  }
  return url
}

/**
 * Map an object to URL query parameters.
 */
export function serializeParams(url: string, params?: RequestParamsType): string {
  const separateQueryParams = []

  for (const key in params) {
    const value = params[key]

    // Convert different types to string
    if (Array.isArray(value)) {
      // For arrays, add the key multiple times
      value.forEach(item => separateQueryParams.push(key + '=' + encodeURIComponent(item)))
    } else if (typeof value === 'boolean') {
      // Convert booleans to 0/1
      separateQueryParams.push(key + '=' + (value ? 1 : 0))
    } else if (value !== undefined && value !== null && value !== '') {
      // Take other existing values as they are
      separateQueryParams.push(key + '=' + encodeURIComponent(value))
    }
  }

  // Return either the query params or an empty string
  return url + ((separateQueryParams.length) ? '?' + separateQueryParams.join('&') : '')
}

/**
 * Format provided Date to ISO 8601 format needed for backend.
 * e.g. '2022-08-31T20:13:09+02:00'
 */
export function formatDateToISO(d: Date): string {
  const pad = (n: number) => n < 10 ? ('0' + n) : n

  const date = d.getFullYear() + '-' + pad(d.getMonth() + 1) + '-' + pad(d.getDate())
  const time = pad(d.getHours()) + ':' + pad(d.getMinutes()) + ':' + pad(d.getSeconds())

  const tzOffset = d.getTimezoneOffset()
  // e.g. tzOffset=300 => timeZone='-05:00'
  const tzPlusMinus = tzOffset <= 0 ? '+' : '-'
  const tzHours = ('0' + Math.floor(Math.abs(tzOffset) / 60)).slice(-2)
  const tzMinutes = ('0' + (tzOffset % 60)).slice(-2)
  const timeZone = `${tzPlusMinus}${tzHours}:${tzMinutes}`

  return `${date}T${time}${timeZone}`
}
