import { useLocale, useTitle } from '@web/shared/utils-intl'
import { RouteWidget } from '@web/widgets/feature-route-widget'

const RouteWidgetView = () => {
  const { intl } = useLocale()
  useTitle(intl.formatMessage({
    id: 'route_widget_document_title',
    defaultMessage: 'Bikemap Web - Precise cycling route planning',
  }))

  return <RouteWidget/>
}

export default RouteWidgetView
