import { Typography } from '@mui/material'
import { useLocale } from '@web/shared/utils-intl'
import { useWidgetContext } from '@web/widgets/utils-basics'
import { RouteWidgetContent, RouteWidgetLayout, RouteWidgetMapContainer } from './route-widget-layout'
import BikeGoneIllustration from '../svgs/bike-gone'

import styles from './route-widget-unavailable.module.scss'

const RouteWidgetUnavailableTitle = () => {
  const { intl } = useLocale()
  const { isMedium, isLarge } = useWidgetContext()

  const title = intl.formatMessage({
    id: 'route_widget_unavailable_title',
    defaultMessage: 'This route has either been set private by its owner or does not exist (anymore).',
  })

  return (
    <Typography
      variant={isLarge ? 'h2' : isMedium ? 'h3' : 'body2'}
      component='h1'
    >
      {title}
    </Typography>
  )
}

export const RouteWidgetUnavailable = () => (
  <RouteWidgetLayout>
    <RouteWidgetMapContainer>
      <div className={styles['map-placeholder']}>
        <BikeGoneIllustration width='50vw' />
      </div>
    </RouteWidgetMapContainer>
    <RouteWidgetContent>
      <RouteWidgetUnavailableTitle />
    </RouteWidgetContent>
  </RouteWidgetLayout>
)
