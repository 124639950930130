import { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4 8.383H3.6l2.583 2.584c.134.133.2.291.2.474a.65.65 0 0 1-.2.476.68.68 0 0 1-.475.183.622.622 0 0 1-.458-.183L1.517 8.183a.588.588 0 0 1-.142-.216.743.743 0 0 1-.042-.25c0-.09.014-.173.042-.25a.589.589 0 0 1 .142-.217L5.25 3.517a.632.632 0 0 1 .467-.184c.188 0 .344.061.466.184a.621.621 0 0 1 .192.474.653.653 0 0 1-.192.459L3.6 7.05h8.8l-2.583-2.6a.653.653 0 0 1-.192-.459.621.621 0 0 1 .192-.474.632.632 0 0 1 .466-.184c.19 0 .345.061.467.184l3.733 3.733a.588.588 0 0 1 .142.217.742.742 0 0 1 .042.25.742.742 0 0 1-.042.25.588.588 0 0 1-.142.216l-3.733 3.734a.621.621 0 0 1-.458.183.68.68 0 0 1-.475-.183.65.65 0 0 1-.2-.476c0-.183.066-.341.2-.474L12.4 8.383Z"
      fill="currentColor"
    />
  </svg>
)

export default svg
