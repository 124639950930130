import { logError } from '@web/shared/utils-error-handling'
import { postRequest, RequestBodyType } from '../network'
import { API_URL_ACCESS_TOKEN } from '../config'
import { AccessTokenDataType, AccessTokenDataExternalType, AccessTokenRequestDataType } from './types'

/**
 * Format access token data from object received from API endpoint.
 */
export function formatAccessTokenFromResponse(data: AccessTokenDataExternalType): AccessTokenDataType {
  return {
    accessToken: data.access_token,
    expiresIn: data.expires_in,
    scope: data.scope,
    tokenType: data.token_type,
    refreshToken: data.refresh_token,
    isLoggedIn: data.is_logged_in,
  }
}

/**
 * Retrieve auth tokens.
 * @throws Error
 */
export async function fetchAccessToken(refreshToken?: string): Promise<AccessTokenDataType> {
  if (!process.env['NX_API_BASE_URL']) {
    throw Error(logError('API URL is not defined'))
  }

  if (!process.env['NX_API_CLIENT_ID'] || !process.env['NX_API_CLIENT_SECRET']) {
    throw Error(logError('Client ID/Secret not defined'))
  }

  const body: AccessTokenRequestDataType = {
    client_id: process.env['NX_API_CLIENT_ID'],
    client_secret: process.env['NX_API_CLIENT_SECRET'],
    // When calling the API on the same host and logged in, we are already authenticated by the cookie
    grant_type: 'web_access_token',
  }

  if (refreshToken) {
    // A refresh token should be used to refresh an existing authentication
    body['grant_type'] = 'refresh_token'
    body['refresh_token'] = refreshToken
  } else if (process.env['NX_API_DEV_USERNAME'] && process.env['NX_API_DEV_PASSWORD']) {

    // TODO: WEB-649 Do not use this in web app, but make it work locally with "https://development.bikemap.net" API
    //  with CORE team...

    // When configured to call the development API with defined user credentials. This is meant for
    // development only.
    body['grant_type'] = 'password'
    body['username'] = process.env['NX_API_DEV_USERNAME']
    body['password'] = process.env['NX_API_DEV_PASSWORD']
  }

  const data = await postRequest(API_URL_ACCESS_TOKEN, {
    body: body as unknown as RequestBodyType,
    type: 'urlencoded',
    credentials: 'include',
  })

  return formatAccessTokenFromResponse(data)
}

export default fetchAccessToken
