import { logError } from '@web/shared/utils-error-handling'
import { getFromUrl } from '@web/shared/utils-navigation'
import React, { createContext, useContext, useMemo } from 'react'

export type UnitPreference = 'metric' | 'imperial'

type RouteWidgetParams = {
  unitPreference: UnitPreference
  isExtended: boolean
  areDistanceMarkersPermanent: boolean
}

const RouteWidgetParamsContext = createContext<RouteWidgetParams>({
  unitPreference: 'metric',
  isExtended: false,
  areDistanceMarkersPermanent: false,
})

interface RouteWidgetParamsProviderProps {
  children: React.ReactNode
}

/**
 * Provides settings from URL parameters for the route widget as context.
 */
export const RouteWidgetParamsProvider = ({
  children,
}: RouteWidgetParamsProviderProps) => {
  const params: RouteWidgetParams = useMemo(() => ({
    unitPreference: getFromUrl('unit') === 'imperial' ? 'imperial' : 'metric',
    isExtended: getFromUrl('extended') === '1',
    areDistanceMarkersPermanent: getFromUrl('distanceMarkers') === '1',
  }), [])

  return (
    <RouteWidgetParamsContext.Provider value={params}>
      {children}
    </RouteWidgetParamsContext.Provider>
  )
}

/**
 * Get settings from URL parameters for the route widget.
 */
export const useRouteWidgetParams = (): RouteWidgetParams => {
  const context = useContext<RouteWidgetParams>(RouteWidgetParamsContext)
  if (!context) {
    logError('useRouteWidgetParams must be used inside RouteWidgetParamsProvider')
  }
  return context
}
