import { Point } from 'geojson'
import { ROUTE_POI_TYPES, RoutePoiEntity, RoutePoiType } from '../entities'

export type RoutePoiResponse = {
  id: number
  image: string | null
  lng_lat: Point
  poi_class: string
  route_id: number
  text?: string
}

export const dummyRoutePoiResponse: RoutePoiResponse = {
  id: 531,
  image: '/poi-image1',
  lng_lat: {
    type: 'Point',
    coordinates: [17.111, 49.114],
  },
  poi_class: 'poi-photo',
  route_id: 100,
  text: 'Some description...',
}

function parseRoutePoiType(res: string): RoutePoiType {
  for (const type of ROUTE_POI_TYPES) {
    if (res.includes(type)) {
      return type
    }
  }
  return 'sight'
}

export function convertToRoutePoiEntity(res: RoutePoiResponse): RoutePoiEntity {
  return {
    id: res.id,
    routeId: res.route_id,
    lng: res.lng_lat.coordinates[0],
    lat: res.lng_lat.coordinates[1],
    type: parseRoutePoiType(res.poi_class),
    description: res.text || undefined,
    image: res.image || undefined,
  }
}
