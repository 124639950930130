/**
 * Values are already the CSS string.
 */
export const styleDefinitions = {
  borderRadiusSmall: '0.25rem',
  borderRadiusMedium: '0.5rem',
  borderRadiusLarge: '1rem',

  fontFamilyDefault: '-apple-system, BlinkMacSystemFont, Roboto, sans-serif',
  lineHeightDefault: '1.5',
  fontWeightMedium: '500', // Can vary depending on font family, so this is a single place to change it

  boxShadowBase: '0 0 0.5rem 0 #00000026',
  boxShadowBaseLight: '0 0 0.5rem 0 rgba(29, 65, 89, 0.25)',
  boxShadow04Dp: '0 2px 4px 0 #00000033, 0 1px 10px 0 #0000001F, 0 4px 5px 0 #00000024',
  boxShadow08Dp: '0 5px 5px 0 #00000033, 0 3px 14px 0 #0000001F, 0 8px 10px 0 #00000024',
  boxShadow12Dp: '0 7px 8px 0 #00000033, 0 5px 22px 0 #0000001F, 0 12px 17px 0 #00000024',

  /**
   * Values are numbers in milliseconds.
   */
  timing: {
    transitionLong: 300,
    transitionShort: 150,
    transitionInstant: 50,
  },
}
