import { RoutePoiType } from '@web/shared/feature-api'
import { useLocale } from '@web/shared/utils-intl'

export const useTypeLabels = (): Record<RoutePoiType, string> => {
  const { intl } = useLocale()

  return {
    sight: intl.formatMessage({
      id: 'route_poi_type_label_sight',
      defaultMessage: 'Point of Interest',
    }),
    photo: intl.formatMessage({
      id: 'route_poi_type_label_photo',
      defaultMessage: 'Photo',
    }),
    repair: intl.formatMessage({
      id: 'route_poi_type_label_repair',
      defaultMessage: 'Bike Service',
    }),
    water: intl.formatMessage({
      id: 'route_poi_type_label_water',
      defaultMessage: 'Drinking Water',
    }),
    hotel: intl.formatMessage({
      id: 'route_poi_type_label_hotel',
      defaultMessage: 'Accommodation',
    }),
  }
}
