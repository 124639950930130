import React from 'react'
import clsx from 'clsx'
import { BikemapLogo, Link } from '@web/shared/ui-components'
import { useLocale } from '@web/shared/utils-intl'
import { getHomeUrl } from '@web/shared/utils-navigation'
import { useWidgetContext } from '@web/widgets/utils-basics'
import { useRouteWidgetParams } from '../context'

import styles from './route-widget-layout.module.scss'

interface RouteWidgetLayoutProps {
  children: React.ReactNode
}

export const RouteWidgetLayout = ({
  children,
}: RouteWidgetLayoutProps) => (
  <div className={styles['default']}>
    {children}
  </div>
)

interface RouteWidgetMapContainerProps {
  children: React.ReactNode
}

export const RouteWidgetMapContainer = ({
  children,
}: RouteWidgetMapContainerProps) => {
  const { isMedium } = useWidgetContext()

  return (
    <div className={clsx(styles['map'], {
      [styles['map-medium']]: isMedium,
    })}>
      {children}
    </div>
  )
}

interface RouteWidgetContentProps {
  children: React.ReactNode
  columns?: boolean
}

export const RouteWidgetContent = ({
  children,
  columns,
}: RouteWidgetContentProps) => {
  const { language } = useLocale()
  const { unitPreference } = useRouteWidgetParams()
  const { isMedium, isLarge } = useWidgetContext()

  const homeUrl = getHomeUrl({
    language,
    unitPreference,
    cookieConsentStatistics: null,
    trackingParameters: {
      utmMedium: 'route_widget',
      utmCampaign: 'widget_logo',
    },
  })

  return (
    <div className={clsx(styles['content'], {
      [styles['content-columns']]: columns,
    })}>
      {children}
      <Link href={homeUrl} target="_blank" alignSelf={isLarge ? 'end' : 'center'} marginTop='0.25rem'>
        <BikemapLogo size={isLarge ? '2rem' : isMedium ? '1.5rem' : '0.75rem'} />
      </Link>
    </div>
  )
}
