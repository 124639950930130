import BikemapText from './svgs/bikemap-text'
import { BikemapIcon } from '../icons'

import styles from './BikemapLogo.module.scss'

export interface BikemapLogoProps {
    size?: string;
}

export const BikemapLogo = ({ size }: BikemapLogoProps) => {
  return (
    <div className={styles['logo-content']} style={{ fontSize: size ?? '2rem' }}>
      <BikemapIcon style={{ fontSize: '1.125em' }} className={styles['bikemap-icon']}/>
      <BikemapText height='1em' />
    </div>
  )
}
