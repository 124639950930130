import { GlobalStylesProps } from '@mui/material'
import { styleDefinitions } from './style-definitions'
import {
  getColorsAsCustomProperties,
  getDefinitionsAsCustomProperties,
  Definitions,
  StylesMode,
} from './get-as-custom-properties'

export type GlobalStylesOptions = {
  transparentBody?: boolean
}

export function getGlobalStyles(mode: StylesMode = 'light', options: GlobalStylesOptions = {}): GlobalStylesProps['styles'] {
  const { timing, ...mainDefinitions } = styleDefinitions

  return {
    html: {
      ...getColorsAsCustomProperties(mode),
      ...getDefinitionsAsCustomProperties(mainDefinitions as unknown as Definitions<string>),
      ...getDefinitionsAsCustomProperties(timing, (time: number) => `${time / 1000}s`),
      fontSize: 16,
      lineHeight: 1.5,
      WebkitTextSizeAdjust: '100%',
    },
    body: {
      margin: 0,
      fontFamily: 'var(--font-family-default)',
      color: 'var(--onNeutral-primary)',
      background: options.transparentBody ? 'transparent' : 'var(--neutral-primary)',
    },
  }
}
