import { ElevationData } from './elevation-curve'

export const roundToNearest = (roundedNum: number, roundedTo: 10 | 100 | 1000) => {
  return roundedNum > 0 ? Math.ceil(roundedNum / roundedTo) * roundedTo : roundedNum
}

export const getMaxDistanceAndElevation = (elevationData: ElevationData[]) => {
  if (elevationData.length === 0) {
    return { maxDistance: 0, maxElevation: 0 }
  }
  let maxDistance = elevationData[0].distance
  let maxElevation = elevationData[0].elevation
  for (let i = 1; i < elevationData.length; ++i) {
    if (elevationData[i].distance > maxDistance) {
      maxDistance = elevationData[i].distance
    }
    if (elevationData[i].elevation > maxElevation) {
      maxElevation = elevationData[i].elevation
    }
  }
  return { maxDistance, maxElevation }
}
