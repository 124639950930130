const svg = (props?: any) => {
  const { cx, cy } = props
  return (
    <svg
      width="26"
      height="42"
      x={cx ? cx - 13 : 0}
      y={cy ? cy - 21 : 0}
      viewBox="0 0 26 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ddd_826_5208)">
        <circle cx="13" cy="21" r="8.5" fill="#0071E8" stroke="white" strokeWidth="2" />
        <circle cx="13" cy="21" r="3" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_ddd_826_5208"
          x="-2"
          y="-2"
          width="30"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_826_5208" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_826_5208" result="effect2_dropShadow_826_5208" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_826_5208" result="effect3_dropShadow_826_5208" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_826_5208" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default svg
