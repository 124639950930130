import { MultiLineString, MultiPoint, Point, Polygon } from 'geojson'
import { RouteBikeTypes, RouteEntity, RouteSurfaces, UserPreviewEntity } from '../entities'
import { dummyMultiGeometry } from '../../testing/data'
import { convertToLineString, geometryCoordinatesToLngLat } from '../utility'

export interface RouteResponse {
  altitude_difference: number
  altitude_difference_back: number
  average_speed: number|null
  bounding_box: Polygon
  category: RouteSurfaces | null
  created: string
  description: string|null
  distance: number
  duration: number|null
  gpx: string
  ground: RouteBikeTypes | null
  has_pois: boolean
  hasz: boolean
  id: number
  is_favorite: boolean
  is_loop: boolean
  is_private: boolean
  kml: string
  location?: string
  points: MultiLineString
  route_favorite_count: number
  route_images: string[]
  start: Point
  staticmap: string
  title: string
  user: {
    id: number
    displayname: string
    is_subscribed: boolean
    avatar_image: string
    slug: string
  }
  views: number
  waypoints?: MultiPoint
  external_id?: string
}

export interface RouteWithCollectionsResponse extends RouteResponse {
  routecollections: number[]
}

export const dummyRouteResponse: RouteResponse = {
  altitude_difference: 100,
  altitude_difference_back: 200,
  average_speed: 3.738,
  bounding_box: {
    type: 'Polygon',
    coordinates: [[[1, 2], [2, 3], [3, 4], [4, 5]]],
  },
  category: [1],
  created: '2023-03-24T16:44:51Z',
  description: 'Some description',
  distance: 2000,
  duration: 535,
  gpx: '/gpx',
  ground: [2, 3],
  has_pois: true,
  hasz: true,
  id: 100,
  is_favorite: false,
  is_loop: false,
  is_private: false,
  kml: '/kml',
  location: 'Ramingstein, Salzburg, Österreich',
  points: dummyMultiGeometry,
  route_favorite_count: 3,
  route_images: ['/image1', '/image2'],
  start: {
    type: 'Point',
    coordinates: [17.111, 49.112],
  },
  staticmap: '/static-map',
  title: 'Title',
  user: {
    id: 12,
    displayname: 'John Doe',
    is_subscribed: false,
    avatar_image: '/avatar',
    slug: 'doe',
  },
  views: 198,
  external_id: '69e77c75-7e51-46e4-83bf-0a215fea6492',
}

/**
 * Up to this number, waypoints of existing routes are treated like actual waypoints. Reason: Older routes can have
 * ridiculous amounts of waypoints, which have actually just been used as a utility to draw the route.
 */
const MAX_REASONABLE_WAYPOINTS = 8

export function convertToRouteEntity(res: RouteResponse): RouteEntity {
  const geometry = convertToLineString(res.points)
  const start = geometryCoordinatesToLngLat(geometry.coordinates[0])
  const end = geometryCoordinatesToLngLat(geometry.coordinates[geometry.coordinates.length - 1])

  return {
    id: res.id,
    title: res.title,
    description: res.description || undefined,
    geometry,
    distance: res.distance,
    ascent: res.altitude_difference,
    descent: res.altitude_difference_back,
    isPrivate: res.is_private,
    waypoints: res.waypoints && res.waypoints.coordinates.length <= MAX_REASONABLE_WAYPOINTS
      ? res.waypoints.coordinates.map(geometryCoordinatesToLngLat)
      : [start, end],
    externalId: res.external_id,
    location: res.location,
    favoriteCount: res.route_favorite_count,
    durationInS: res.duration ?? undefined,
    averageSpeedInMs: res.average_speed ?? undefined,
    created: new Date(res.created).getTime(),
    surfaces: res.ground || [],
    bikeTypes: res.category || [],
    images: res.route_images,
    isFavorite: res.is_favorite,
    gpx: res.gpx,
    kml: res.kml,
    hasPois: res.has_pois,
  }
}

export function convertToUserEntity(res: RouteResponse['user']): UserPreviewEntity {
  return {
    id: res.id,
    slug: res.slug,
    name: res.displayname,
    avatar: res.avatar_image,
    isPremium: res.is_subscribed,
  }
}

export interface RouteImageResponse {
  id: number
  route: number
  image: string
  url: string
}

export const dummyRouteImageResponse: RouteImageResponse = {
  id: 934,
  route: 100,
  image: 'w0948htrfwu',
  url: '/image1',
}
