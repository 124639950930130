import clsx from 'clsx'
import { Skeleton, Typography } from '@mui/material'
import { RouteEntity } from '@web/shared/feature-api'
import { useLocale } from '@web/shared/utils-intl'
import { Link, UserContent } from '@web/shared/ui-components'
import { useWidgetContext } from '@web/widgets/utils-basics'
import { useRouteDetailUrl } from './use-route-detail-url'

import styles from './route-widget-description.module.scss'

interface LoadedDescriptionProps {
  routeId: number
  description: string
}

const LoadedDescription = ({
  routeId,
  description,
}: LoadedDescriptionProps) => {
  const { intl } = useLocale()
  const { isLarge } = useWidgetContext()

  const routeDetailUrl = useRouteDetailUrl(routeId, 'widget_route_description')

  const readMoreLabel = intl.formatMessage({
    id: 'route_widget_read_more_link',
    defaultMessage: '...read more',
  })

  return (
    <Typography
      variant={isLarge ? 'body1' : 'body2'}
      component='div'
      className={clsx(styles['container'], { [styles['container-large']]: isLarge })}
    >
      <UserContent squeezed secondary>
        {description}
      </UserContent>
      <span className={styles['read-more']}>
        <Link
          href={routeDetailUrl}
          target="_blank"
          small={!isLarge}
        >
          {readMoreLabel}
        </Link>
      </span>
    </Typography>
  )
}

const LoadedNoDescription = () => {
  const { intl } = useLocale()
  const { isLarge } = useWidgetContext()

  const noDescriptionPlaceholder = intl.formatMessage({
    id: 'route_widget_no_description_placeholder',
    defaultMessage: 'No description yet.',
  })

  return (
    <Typography
      variant={isLarge ? 'body1' : 'body2'}
      color='textSecondary'
      component='p'
    >
      {noDescriptionPlaceholder}
    </Typography>
  )
}

interface RouteWidgetDescriptionProps {
  route?: RouteEntity
}

export const RouteWidgetDescription = ({
  route,
}: RouteWidgetDescriptionProps) => {
  const { isMedium, isLarge } = useWidgetContext()

  return isMedium
    ? route
      ? route.description
        ? <LoadedDescription routeId={route.id} description={route.description} />
        : <LoadedNoDescription />
      : (
        <Typography fontSize={isLarge ? '1rem' : '0.75rem'} data-testid='route-widget-description-skeleton'>
          <Skeleton />
          <Skeleton width='70%' />
        </Typography>
      )
    : null
}
