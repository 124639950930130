export const API_BASE_URL = process.env['NX_API_BASE_URL']
export const ROUTING_API_BASE_URL = process.env['NX_ROUTING_API_BASE_URL']

export const API_URL_ACCESS_TOKEN = API_BASE_URL + '/v4/oauth2/access_token'
export const API_URL_USER = API_BASE_URL + '/v5/users/:userId/'
export const API_URL_GARMIN = API_BASE_URL + '/v5/users/:userId/garmin_token/'
export const API_URL_GEO_IP = API_BASE_URL + '/v4/geoip_lookup/'
export const API_URL_DEFAULT_MAP_STYLE = API_BASE_URL + '/v5/map_styles/default/style/?is_inhoused=true'
export const API_URL_MAP_STYLES = API_BASE_URL + '/v4/map_styles/?is_inhoused=true'
export const API_URL_ROUTES = API_BASE_URL + '/v5/routes/'
export const API_URL_ROUTE = API_BASE_URL + '/v5/routes/:routeId/'
export const API_URL_ROUTE_UPLOAD_IMAGE = API_BASE_URL + '/v5/routes/:routeId/upload_image/'
export const API_URL_ROUTE_IMAGES = API_BASE_URL + '/v5/routes/:routeId/image/'
export const API_URL_ROUTE_IMAGE = API_BASE_URL + '/v5/routes/:routeId/image/:routeImageId/'
export const API_URL_ROUTE_ROUTE_POIS = API_BASE_URL + '/v5/routes/:routeId/pois/'
export const API_URL_ROUTE_POIS = API_BASE_URL + '/v5/routepois/'
export const API_URL_ROUTE_POI = API_BASE_URL + '/v5/routepois/:routePoiId/'
export const API_URL_ROUTE_FAVOR = API_BASE_URL + '/v5/routes/:routeId/favor/'
export const API_URL_ROUTE_UNFAVOR = API_BASE_URL + '/v5/routes/:routeId/unfavor/'
export const API_URL_GEO_FILE_UPLOAD = API_BASE_URL + '/v5/geo_file_upload/'
export const API_URL_GEO_ROUTE_SIMPLIFICATION = API_BASE_URL + '/v5/georoutesimplification/'
export const API_URL_USER_ROUTE_COLLECTIONS = API_BASE_URL + '/v5/users/:userId/collections/'
export const API_URL_ROUTE_COLLECTION_ROUTES = API_BASE_URL + '/v5/routecollections/:routeCollectionId/routes/'
export const API_URL_ROUTE_COLLECTIONS = API_BASE_URL + '/v5/routecollections/'
export const API_URL_DEEPLINK = API_BASE_URL + '/v5/deeplink/'

export const API_URL_ROUTING = ROUTING_API_BASE_URL + '/route'
