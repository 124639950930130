import { RoutePoiDetails } from './details/route-poi-details'
import { useRoutePois } from './route-pois-context'

/**
 * Shows content of the currently relevant route POI.
 */
export const RoutePoiContent = () => {
  const { selectedRoutePoi } = useRoutePois()

  return selectedRoutePoi ? <RoutePoiDetails routePoi={selectedRoutePoi} /> : null
}
