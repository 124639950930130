import React from 'react'
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
} from '@mui/material/styles'
import { colors } from '@web/shared/ui-design-system'
import { MuiSwitch } from '../Switch/theme-overrides'
import { styleDefinitions } from './helpers/style-definitions'

export interface ThemeProviderProps {
  children: React.ReactNode
}

/**
 * When using a MUI component within our UI components, make sure all necessary customization
 * is configured here so that it appears consistent.
 */
const theme = createTheme({
  palette: {
    primary: {
      main: colors.actionColor.primary,
    },
    text: {
      secondary: colors.onNeutral.secondary,
      tertiary: colors.onNeutral.tertiary,
    },
    onColor: colors.onColor.primary,
    error: {
      main: colors.dangerColor.primary,
    },
  },
  transitions: {
    duration: {
      enteringScreen: styleDefinitions.timing.transitionLong,
      leavingScreen: styleDefinitions.timing.transitionShort,
    },
  },
  components: {
    MuiSwitch,
    MuiTypography: {
      styleOverrides: {
        paragraph: {
          marginBottom: '0.5rem',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.neutral.tertiary,
        },
      },
    },
  },
  typography: {
    fontFamily: styleDefinitions.fontFamilyDefault,
    fontWeightBold: styleDefinitions.fontWeightMedium,
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
      lineHeight: 1.333,
      marginBottom: '0.5rem',
    },
    h2: {
      fontSize: '1.5rem',
      lineHeight: 1.333,
      fontWeight: styleDefinitions.fontWeightMedium,
      marginBottom: '0.5rem',
    },
    h3: {
      fontSize: '1.25rem',
      lineHeight: 1.3,
      fontWeight: styleDefinitions.fontWeightMedium,
      marginBottom: '0.5rem',
    },
    h4: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: styleDefinitions.fontWeightMedium,
      marginBottom: '0.5rem',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.75rem',
      lineHeight: 1.5,
    },
  },
} as ThemeOptions)

export const ThemeProvider = ({
  children,
}: ThemeProviderProps) => (
  <MuiThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      {children}
    </StyledEngineProvider>
  </MuiThemeProvider>
)

export default ThemeProvider
