import { MarkerProps } from 'react-map-gl'
import svg from './svgs/map-marker-accommodation.svg'
import { CommonMapMarker } from './shared/common-map-marker'

/**
 * Map marker representing an accommodation.
 */
export const MapMarkerAccommodation = (props: MarkerProps) => (
  <CommonMapMarker {...props} svg={svg} />
)
