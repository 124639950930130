import { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 116 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.5498 14.62C15.5498 19.5926 12.7061 22.4362 8.93945 22.4362C7.17521 22.4362 5.56729 21.6621 4.36136 20.2105V20.3668C4.36136 21.4462 3.40107 22.2799 2.38124 22.2799C1.27208 22.2799 0.401123 21.3866 0.401123 20.2998V3.21571C0.401123 2.13633 1.26463 1.2356 2.38124 1.2356C3.43085 1.2356 4.33158 2.12888 4.33158 3.21571V9.05929C5.50774 7.51093 7.17521 6.77397 8.93945 6.77397C12.7434 6.77397 15.5498 9.74414 15.5498 14.6274V14.62ZM11.5002 14.62C11.5002 12.022 9.98906 10.2652 7.69629 10.2652C6.4308 10.2652 4.97921 11.0096 4.32414 11.9997V17.285C4.97177 18.2452 6.42336 18.9524 7.69629 18.9524C9.98161 18.9524 11.5002 17.2254 11.5002 14.6274V14.62Z"
      fill="#0873E7"
    />
    <path
      d="M18.0513 2.35232C18.0513 1.05705 19.1307 0 20.4036 0C21.6765 0 22.7559 1.04961 22.7559 2.35232C22.7559 3.65503 21.7063 4.70464 20.4036 4.70464C19.1009 4.70464 18.0513 3.65503 18.0513 2.35232ZM18.4532 20.3074V8.90308C18.4532 7.8237 19.3168 6.92297 20.4334 6.92297C21.483 6.92297 22.3837 7.81625 22.3837 8.90308V20.3074C22.3837 21.3868 21.4904 22.2875 20.4036 22.2875C19.3168 22.2875 18.4532 21.3942 18.4532 20.3074Z"
      fill="#0873E7"
    />
    <path
      d="M39.8101 20.3073C39.8101 21.4536 38.9764 22.2874 37.8598 22.2874C37.2717 22.2874 36.7432 22.0715 36.3486 21.61L31.9566 16.3545L30.1031 18.0889V20.3147C30.1031 21.3941 29.2098 22.2948 28.1229 22.2948C27.0361 22.2948 26.1726 21.4015 26.1726 20.3147V3.21571C26.1726 2.13633 27.0361 1.2356 28.1527 1.2356C29.2023 1.2356 30.1031 2.12888 30.1031 3.21571V13.7267L36.2816 7.45138C36.6538 7.07917 37.1154 6.92285 37.5769 6.92285C38.5967 6.92285 39.4007 7.75658 39.4007 8.74664C39.4007 9.20817 39.2146 9.70692 38.8722 10.0419L34.8226 13.9947L39.4007 19.0939C39.6463 19.3395 39.8027 19.8383 39.8027 20.2998L39.8101 20.3073Z"
      fill="#0873E7"
    />
    <path
      d="M48.6442 6.76648C53.0957 6.76648 56.1254 10.1014 56.1254 14.0021C56.1254 15.2974 55.2917 15.8557 53.8103 15.8557H45.0338C45.2795 17.739 46.798 19.3171 49.329 19.3171C50.3191 19.3171 51.4282 19.0045 52.3587 18.4834C52.5746 18.3569 52.8203 18.2973 53.1032 18.2973C53.9369 18.2973 54.6515 19.0045 54.6515 19.8457C54.6515 20.4338 54.3091 20.9251 53.8178 21.2079C52.3662 22.0417 50.6317 22.4436 48.9047 22.4436C44.3638 22.4436 40.9321 19.3841 40.9321 14.5902C40.9321 10.2652 44.1182 6.77392 48.6591 6.77392L48.6442 6.76648ZM44.9668 13.1981H52.3215C52.2322 11.7763 51.2421 9.89298 48.6442 9.89298C46.2025 9.89298 45.1529 11.7168 44.9668 13.1981Z"
      fill="#0873E7"
    />
    <path
      d="M81.0634 11.2776V20.2998C81.0634 21.3791 80.1701 22.2799 79.0833 22.2799C77.9965 22.2799 77.1032 21.3866 77.1032 20.2998V12.6324C77.1032 11.2404 76.4853 10.2503 74.8774 10.2503C73.4854 10.2503 72.3464 11.1808 71.7881 11.9848V20.2998C71.7881 21.3791 70.8949 22.2799 69.808 22.2799C68.7212 22.2799 67.8279 21.3866 67.8279 20.2998V12.6324C67.8279 11.2404 67.2101 10.2503 65.6021 10.2503C64.2399 10.2503 63.1009 11.1808 62.5129 12.0145V20.2998C62.5129 21.3791 61.6196 22.2799 60.5625 22.2799C59.4534 22.2799 58.5824 21.3866 58.5824 20.2998V8.89548C58.5824 7.78631 59.4459 6.88558 60.5327 6.88558C61.6196 6.88558 62.5426 7.77887 62.5426 8.89548V9.0518C63.1903 8.15852 65.1406 6.76648 67.3961 6.76648C69.6517 6.76648 70.9842 7.78631 71.535 9.48356C72.3986 8.1213 74.3787 6.76648 76.664 6.76648C79.4183 6.76648 81.056 8.21807 81.056 11.2776H81.0634Z"
      fill="#0873E7"
    />
    <path
      d="M97.4424 12.4237V20.24C97.4424 21.3491 96.5789 22.2796 95.4623 22.2796C94.4722 22.2796 93.6087 21.5352 93.4821 20.5154C92.4921 21.6916 90.7353 22.4285 88.7849 22.4285C86.4028 22.4285 83.5964 20.8206 83.5964 17.4857C83.5964 14.1507 86.4103 12.7289 88.7849 12.7289C90.7651 12.7289 92.5218 13.3468 93.5119 14.5527V12.4833C93.5119 10.9721 92.2166 9.98207 90.2365 9.98207C89.0604 9.98207 88.0108 10.2947 86.9314 10.9721C86.7155 11.0987 86.4401 11.188 86.1572 11.188C85.3532 11.188 84.6758 10.5106 84.6758 9.67686C84.6758 9.11856 84.9885 8.62725 85.383 8.3816C87.0802 7.27243 89.0008 6.77368 90.9139 6.77368C94.3159 6.77368 97.4349 8.13594 97.4349 12.4312L97.4424 12.4237ZM93.5194 18.4832V16.6296C92.8717 15.7661 91.636 15.3343 90.3631 15.3343C88.8147 15.3343 87.5492 16.1383 87.5492 17.5899C87.5492 19.0415 88.8147 19.7859 90.3631 19.7859C91.6286 19.7859 92.8643 19.3541 93.5194 18.4906V18.4832Z"
      fill="#0873E7"
    />
    <path
      d="M115.768 14.5902C115.768 19.5628 112.984 22.4436 109.158 22.4436C107.393 22.4436 105.785 21.6992 104.55 20.1583V26.0019C104.55 27.0813 103.656 27.982 102.599 27.982C101.49 27.982 100.619 27.0887 100.619 26.0019V8.90292C100.619 7.79376 101.483 6.86325 102.599 6.86325C103.716 6.86325 104.58 7.79376 104.58 8.90292V8.99225C105.756 7.54066 107.364 6.76648 109.158 6.76648C112.991 6.76648 115.768 9.61011 115.768 14.5827V14.5902ZM111.689 14.5902C111.689 12.022 110.177 10.2652 107.914 10.2652C106.649 10.2652 105.197 11.0096 104.542 11.9624V17.2477C105.19 18.1782 106.642 18.9449 107.914 18.9449C110.17 18.9449 111.689 17.1807 111.689 14.5902Z"
      fill="#0873E7"
    />
  </svg>
)

export default svg
