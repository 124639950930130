import { LineString, Position } from 'geojson'
import { useMemo } from 'react'
import { MapMarkerPoint, MapRouteLine } from '@web/shared/feature-map'
import { useElevationCurveContext } from '../context'
import { SelectionIndexes } from '../types'

interface SelectionMapRouteSectionProps {
  mapRouteId: string
  geometry: LineString
  selectionIndexes: SelectionIndexes
}

export const SelectionMapRouteSection = ({
  mapRouteId,
  geometry,
  selectionIndexes,
}: SelectionMapRouteSectionProps) => {
  const { elevationPointIndex } = useElevationCurveContext()

  const coordinates: Position[] | null = useMemo(() => {
    const indexes = getSelectedSectionIndexes(selectionIndexes, elevationPointIndex)
    if (indexes) {
      const { start, end } = indexes
      return geometry?.coordinates.slice(start, end)
    }
    return null
  }, [geometry, selectionIndexes, elevationPointIndex])

  return coordinates?.length ? (
    <>
      <MapRouteLine
        type='section'
        id='elevation-curve-selection'
        mapRouteId={mapRouteId}
        geometry={{
          type: 'LineString',
          coordinates,
        }}
      />
      <MapMarkerPoint
        longitude={coordinates[0][0]}
        latitude={coordinates[0][1]}
      />
      <MapMarkerPoint
        longitude={coordinates[coordinates.length - 1][0]}
        latitude={coordinates[coordinates.length - 1][1]}
      />
    </>
  ) : null
}

/**
 * Get the indexes of the start and end geometry coordinates that define a selected section. The selection can be
 * explicitly defined by `selectionIndexes` or a preview consisting of one fixed selection index and the
 * `elevationPointIndex` (while hovering).
 */
export function getSelectedSectionIndexes(
  selectionIndexes: SelectionIndexes,
  elevationPointIndex?: number
): { start: number, end: number } | null {
  if (selectionIndexes.length === 2) {
    return {
      start: selectionIndexes[0],
      end: selectionIndexes[1] + 1,
    }
  }
  if (elevationPointIndex !== undefined) {
    return {
      start: Math.min(selectionIndexes[0], elevationPointIndex),
      end: Math.max(selectionIndexes[0], elevationPointIndex) + 1,
    }
  }
  return null
}
