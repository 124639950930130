import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { BaseIntlProvider, useSyncLangAttribute, useUrlLanguageLocal } from '@web/shared/utils-intl'
import { GlobalThemeStyles, ThemeProvider } from '@web/shared/ui-components'
import { WIDGETS_BASE_ROUTE } from '@web/shared/utils-navigation'
import FallbackView from '../views/fallback-view'
import RouteWidgetView from '../views/route-widget'

const AppContent = () => {
  useSyncLangAttribute()

  return (
    <Router>
      <Routes>
        <Route path={`${WIDGETS_BASE_ROUTE}/*`} element={<RouteWidgetView />} />
        <Route path="*" element={<FallbackView />} />
      </Routes>
    </Router>
  )
}

const UIProviders = (props: { children: React.ReactElement }) => {
  return (
    <ThemeProvider>
      <GlobalThemeStyles transparentBody />
      {props.children}
    </ThemeProvider>
  )
}

export function App() {
  const locale = useUrlLanguageLocal()

  return (
    <BaseIntlProvider locale={locale}>
      <UIProviders>
        <AppContent />
      </UIProviders>
    </BaseIntlProvider>
  )
}

export default App
