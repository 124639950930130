import clsx from 'clsx'
import { Skeleton, Typography } from '@mui/material'
import { RouteEntity } from '@web/shared/feature-api'
import { Link } from '@web/shared/ui-components'
import { useRouteDetailUrl } from './use-route-detail-url'
import { useWidgetContext } from '@web/widgets/utils-basics'

import styles from './route-widget-title.module.scss'

interface TitleLinkProps {
  route: RouteEntity
}

const TitleLink = ({
  route,
}: TitleLinkProps) => {
  const routeDetailUrl = useRouteDetailUrl(route.id, 'widget_route_title')

  return (
    <Link href={routeDetailUrl} target="_blank">
      {route.title}
    </Link>
  )
}

interface RouteWidgetTitleProps {
  route?: RouteEntity
}

export const RouteWidgetTitle = ({ route }: RouteWidgetTitleProps) => {
  const { isMedium, isLarge } = useWidgetContext()

  return (
    <Typography
      variant={isLarge ? 'h2' : isMedium ? 'h3' : 'body2'}
      component='h1'
      className={clsx(styles['default'], {
        [styles['medium']]: isMedium,
        [styles['large']]: isLarge,
      })}
    >
      {route ? <TitleLink route={route} /> : <Skeleton width='70%' data-testid='route-widget-title-skeleton' />}
    </Typography>
  )
}
