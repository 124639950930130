import { useEffect, useState } from 'react'
import { authToken } from './auth-token'

export const useAuthHeader = (initialState: string): [string, boolean, string] => {
  const [authHeader, setAuthHeader] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState('idle')

  const init = async () => {
    setIsLoading(true)
    setStatus('fetching')

    const authTokenResponse = await authToken.getAuthHeader()
    setIsLoading(false)

    if (authTokenResponse) {
      setAuthHeader(authTokenResponse)
      setStatus('fetched')
    } else {
      setStatus('failed')
    }
  }

  useEffect(() => {
    init()
  }, [])

  return [authHeader, isLoading, status]
}

export default useAuthHeader
