import { SVGProps } from 'react'

const svg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 58" {...props}>
    <g filter="url(#map-marker-water-filter)">
      <path fill="#000" fillOpacity=".15" d="M18 53.333c7.364 0 13.333-2.387 13.333-5.333S25.363 42.667 18 42.667c-7.364 0-13.334 2.387-13.334 5.333s5.97 5.333 13.334 5.333Z"/>
    </g>
    <path fill="url(#map-marker-water-gradient)" stroke="#fff" strokeWidth="1.5" d="M18 1c4.143 0 7.894 1.666 10.61 4.36A14.774 14.774 0 0 1 33 15.88c0 10.122-11.498 26.58-14.22 30.364-.18.25-.421.756-.78.756-.359 0-.6-.507-.778-.753C14.498 42.459 3 26.002 3 15.88c0-4.108 1.678-7.827 4.39-10.52A15.014 15.014 0 0 1 18 1Z"/>
    <path fill="#fff" d="M18 24.333c-1.764 0-3.316-.614-4.656-1.843-1.34-1.23-2.01-2.893-2.01-4.99 0-1.32.5-2.75 1.5-4.292 1-1.541 2.513-3.208 4.541-5A.998.998 0 0 1 18 7.98c.111 0 .222.021.334.063a1 1 0 0 1 .291.166c2.028 1.792 3.542 3.459 4.542 5 1 1.542 1.5 2.972 1.5 4.292 0 2.097-.67 3.76-2.011 4.99-1.34 1.229-2.892 1.843-4.656 1.843Zm.23-2.5a.666.666 0 0 0 .427-.197.584.584 0 0 0 .177-.428.613.613 0 0 0-.188-.468.602.602 0 0 0-.48-.157c-.569.042-1.173-.114-1.812-.468-.639-.354-1.041-.997-1.208-1.927a.612.612 0 0 0-.22-.375.62.62 0 0 0-.405-.146.603.603 0 0 0-.48.218.564.564 0 0 0-.124.51c.236 1.265.792 2.168 1.667 2.71.875.54 1.756.784 2.645.728Z"/>
    <defs>
      <linearGradient id="map-marker-water-gradient" x1="27.44" x2="-1.476" y1="6.126" y2="29.413" gradientUnits="userSpaceOnUse">
        <stop stopColor="#51B64E"/>
        <stop offset="1" stopColor="#336732"/>
      </linearGradient>
      <filter id="map-marker-water-filter" width="34.666" height="18.667" x=".667" y="38.667" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur result="effect1_foregroundBlur_2537_11286" stdDeviation="2"/>
      </filter>
    </defs>
  </svg>
)

export default svg
