import { MapViewport } from '@web/shared/feature-map'

export const MAP_ID = 'bm-widget-map'

/**
 * Default (Vienna) location to be used for map initialization before a better location can be determined.
 */
export const DEFAULT_VIEWPORT: MapViewport = {
  center: [16.37208, 48.20849],
  zoom: 3,
  bearing: 0,
  pitch: 0,
}
