import { LineString, MultiLineString } from 'geojson'
import { GeocoderLocation } from '../lib/services'

export const dummyHeaders = {
  Authorization: 'Bearer abc123',
  'X-Local-Timezone': 'Europe/Vienna',
}

export const dummyGeometry: LineString = {
  type: 'LineString',
  coordinates: [[17.111, 49.112, 111.0], [17.113, 49.114, 111.0], [17.113, 49.114, 111.0]],
}

export const dummyMultiGeometry: MultiLineString = {
  type: 'MultiLineString',
  coordinates: [
    [[17.111, 49.112, 111.0], [17.113, 49.114, 111.0], [17.113, 49.114, 111.0]],
    [[15.111, 47.112, 110.0], [15.113, 47.114, 111.1]],
  ],
}

export const dummyImageFile = new File(['imagefilebits'], 'foo.jpg', { type: 'image/jpeg' })

export const dummyGeocoderLocation: GeocoderLocation = {
  position: {
    lng: 7.8279232,
    lat: 52.8555979,
  },
  address: 'Hegel, Lindern (Oldenburg), Germany',
}
