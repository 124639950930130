import clsx from 'clsx'
import React from 'react'
import { AnalyticsObjectType } from '../../shared/types'
import styles from './BaseButton.module.scss'

export type BaseButtonVariants = (
  'primary' |
  'secondary' |
  'accent' |
  'danger-primary' |
  'danger-secondary' |
  'secondary-contrast' |
  'ghost-primary' |
  'ghost-secondary' |
  'ghost-danger' |
  'onColor-primary' |
  'onColor-secondary' |
  'onColor-ghost'
)

export interface BaseButtonProps {
  children?: React.ReactNode
  className?: string
  variant?: BaseButtonVariants
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement|HTMLAnchorElement>
  type?: (
    'button' |
    'submit' |
    'reset'
  )
  href?: string
  hrefType?: string
  analytics?: AnalyticsObjectType|Record<string, never>
  tabIndex?: number
  ariaLabel?: string
  coveringClickTarget?: boolean
  onColorBaseColor?: string
}

export const BaseButton = ({
  children,
  className,
  variant,
  disabled,
  type,
  href,
  hrefType,
  analytics = {},
  ariaLabel,
  coveringClickTarget,
  onColorBaseColor,
  ...rest
}: BaseButtonProps) => {
  const commonProps = {
    className: clsx(styles['root'], (variant && styles[variant]), className, {
      [styles['disabled']]: disabled,
      [styles['covering-click-target']]: coveringClickTarget,
    }),
    'data-test-variant': variant,
    'aria-label': ariaLabel,
    style: onColorBaseColor ? { '--button-onColor-base-color': onColorBaseColor } as React.CSSProperties : undefined,
    ...analytics,
    ...rest,
  }
  if (href) {
    return <a {...commonProps} href={href} type={hrefType}>{children}</a>
  } else {
    return <button {...commonProps} disabled={disabled} type={type}>{children}</button>
  }
}

export default BaseButton
