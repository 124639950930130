import { Box } from '@mui/material'
import { ElevationCurveWithSkeleton } from '@web/shared/feature-elevation-curve'
import { useWidgetContext } from '@web/widgets/utils-basics'

export const RouteWidgetElevationCurve = () => {
  const { isLarge } = useWidgetContext()

  return isLarge
    ? (
      <Box width='100%' marginBottom='0.5rem'>
        <ElevationCurveWithSkeleton height='8rem' />
      </Box>
    )
    : null
}
