import { Source, Layer, LayerProps, useMap } from 'react-map-gl'
import { LineString } from 'geojson'
import { colors } from '@web/shared/ui-design-system'
import {
  ROUTE_DESTINATION_MARKER_LAYER_ID,
  ROUTE_GEOMETRY_SOURCE_ID,
  ROUTE_LINE_LAYER_ID,
  ROUTE_OUTLINE_LAYER_ID,
} from '../settings'
import { findBeforeIdBehindSymbols, findBeforeIdOnTop } from '../helpers'

export interface MapRouteLineProps {
  id: string

  /** Map route ID behind which this line should be drawn */
  beforeMapRouteId?: string

  /** Map route ID in which this line should be drawn (right after its line)  */
  mapRouteId?: string

  geometry?: LineString
  type?: 'default'|'inactive'|'section'
}

/**
 * Only the line of a route or route segment.
 */
export const MapRouteLine = ({
  id,
  beforeMapRouteId,
  mapRouteId,
  geometry,
  type = 'default',
}: MapRouteLineProps) => {
  const { current: map } = useMap()

  const lineColor = type === 'inactive'
    ? colors.polylineComp.inactive.foreground
    : type === 'section'
      ? colors.polylineComp.alternate.foreground
      : colors.polylineComp.foreground

  const outlineColor = type === 'inactive'
    ? colors.polylineComp.inactive.background
    : type === 'section'
      ? colors.polylineComp.alternate.background
      : colors.polylineComp.background

  const beforeId: string | undefined = mapRouteId && map ? (
    findBeforeIdOnTop(map, ROUTE_DESTINATION_MARKER_LAYER_ID.replace('{baseId}', mapRouteId))
  ) : map ? (
    findBeforeIdBehindSymbols(map, beforeMapRouteId ? ROUTE_OUTLINE_LAYER_ID.replace('{baseId}', beforeMapRouteId) : undefined)
  ) : undefined

  const lineLayerProps: LayerProps = {
    id: ROUTE_LINE_LAYER_ID.replace('{baseId}', id),
    beforeId,
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-width': [
        'interpolate', ['linear'], ['zoom'],
        // zoom is <=10 -> 3px
        10, 3,
        // zoom is >=22 -> 6px
        22, 6,
      ],
      'line-color': lineColor,
    },
  }

  const outlineLayerProps: LayerProps = {
    id: ROUTE_OUTLINE_LAYER_ID.replace('{baseId}', id),
    beforeId,
    type: 'line',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-width': [
        'interpolate', ['linear'], ['zoom'],
        // zoom is <=10 -> 6px
        10, 6,
        // zoom is >=22 -> 12px
        22, 12,
      ],
      'line-color': outlineColor,
    },
  }

  return geometry ? (
    <Source
      id={ROUTE_GEOMETRY_SOURCE_ID.replace('{baseId}', id)}
      type='geojson'
      data={{
        type: 'Feature',
        geometry,
        properties: null,
      }}
    >
      <Layer {...outlineLayerProps} />
      <Layer {...lineLayerProps} />
    </Source>
  ) : null
}

export default MapRouteLine
