import { createContext, useContext } from 'react'
import { WidgetSize } from './use-widget-size'
import { logError } from '@web/shared/utils-error-handling'

type WidgetContextValue = WidgetSize

export const WidgetContext = createContext<WidgetContextValue>({
  isMedium: false,
  isLarge: false,
})

/**
 * Provides general context for all kinds of widgets.
 */
export const useWidgetContext = (): WidgetContextValue => {
  const context = useContext<WidgetContextValue>(WidgetContext)
  if (!context) {
    logError('useWidgetContext must be used inside WidgetContainer')
  }
  return context
}
