import { useCallback, useMemo } from 'react'
import { throttle } from 'lodash'
import { Skeleton } from '@mui/material'
import { localizeNumber } from '@web/shared/feature-api'
import { ElevationCurve as ElevationCurveComponent, styleDefinitions } from '@web/shared/ui-components'
import { useLocale } from '@web/shared/utils-intl'
import { useSelectionValues } from './use-selection-values'
import { SelectionIndexes } from '../types'
import { useElevationCurveContext } from '../context/elevation-curve-context'

interface ElevationCurveProps {
  height: string
  interactive?: boolean
}

export const ElevationCurve = ({
  height,
  interactive = true,
}: ElevationCurveProps) => {
  const { language } = useLocale()

  const {
    elevation,
    unitPreference,
    selectionIndexes,
    onElevationPointIndexChange,
    onSelectionIndexesChange,
  } = useElevationCurveContext()

  const hasSelection = selectionIndexes?.length === 2

  const { ascent, descent } = useSelectionValues()

  const isImperial = unitPreference === 'imperial'

  const smallUnit = isImperial ? 'ft' : 'm'
  const largeUnit = isImperial ? 'mi' : 'km'
  const largeUnitDivisor = isImperial ? 5280 : 1000

  const formatSmallLength = useCallback(
    (value: string | number) => {
      const number = Math.round(Number(value))
      return `${localizeNumber(number, language)} ${smallUnit}`
    },
    [language, smallUnit]
  )

  const formatLargeLength = useCallback(
    (value: string | number) => {
      const number = Number(value)
      if (number < largeUnitDivisor) {
        return formatSmallLength(number)
      }
      const numberInLargeUnit = Math.round((number / largeUnitDivisor) * 10) / 10
      return `${localizeNumber(numberInLargeUnit, language)} ${largeUnit}`
    },
    [formatSmallLength, language, largeUnit, largeUnitDivisor]
  )

  const onClick = useCallback((index: number) => {
    if (hasSelection) {
      onSelectionIndexesChange()
    } else if (selectionIndexes?.length === 1) {
      onSelectionIndexesChange([selectionIndexes[0], index].sort((a, b) => a - b) as SelectionIndexes)
    } else {
      onSelectionIndexesChange([index])
    }
  }, [hasSelection, onSelectionIndexesChange, selectionIndexes])

  const throttleTimeout: number = useMemo(() => elevation && elevation.length > 200000 ? 150 : 30, [elevation])

  const onHover = useMemo(() => throttle((index: number) => {
    if (!hasSelection) {
      onElevationPointIndexChange(index)
    }
  }, throttleTimeout), [hasSelection, onElevationPointIndexChange, throttleTimeout])

  const onHoverOut = useCallback(() => {
    onHover.cancel()
    onElevationPointIndexChange()
  }, [onElevationPointIndexChange, onHover])

  return elevation ? (
    <ElevationCurveComponent
      height={height}
      elevationData={elevation}
      selectionIndexes={selectionIndexes}
      ascent={formatSmallLength(ascent)}
      descent={formatSmallLength(descent)}
      tooltipLabelFormatter={formatSmallLength}
      xAxisFormatter={formatLargeLength}
      yAxisFormatter={formatSmallLength}
      onClick={onClick}
      onHover={onHover}
      onHoverOut={onHoverOut}
      interactive={interactive}
    />
  ) : null
}

interface ElevationCurveWithSkeletonProps {
  height: string
}

export const ElevationCurveWithSkeleton = ({
  height,
}: ElevationCurveWithSkeletonProps) => {
  const { elevation } = useElevationCurveContext()

  return elevation === undefined
    ? (
      <Skeleton
        variant='rectangular'
        height={height}
        data-testid='ElevationCurveSkeleton'
        style={{ borderRadius: styleDefinitions.borderRadiusSmall }}
      />
    )
    : <ElevationCurve height={height} />
}
