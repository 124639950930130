import { isEmpty } from 'lodash'
import { extractFromUrl, getFromUrl } from '@web/shared/utils-navigation'
import { availableLocales, defaultLocale } from './config'
import { AvailableLocalesType } from './types'

/**
 * Get language only if it's one of our available languages.
 */
function filterLanguage(language: string): AvailableLocalesType|undefined {
  const lang = language.slice(0, 2).toLowerCase() as AvailableLocalesType
  return availableLocales.includes(lang) ? lang : undefined
}

/**
 * Try to find language in URL and also remove it if found.
 */
export function extractUrlLanguage(): AvailableLocalesType|undefined {
  const fromUrl = extractFromUrl('language')
  return fromUrl ? filterLanguage(fromUrl) : undefined
}

/**
 * Get preferred language from browser.
 */
export function getBrowserLanguage(): AvailableLocalesType|undefined {
  try {
    const language = !isEmpty(window.navigator.languages) ? window.navigator.languages[0] : window.navigator.language
    return filterLanguage(language)
  } catch (e) {
    return undefined
  }
}

/**
 * Get preferred language in prioritized order.
 */
export function getPreferredLanguage(): AvailableLocalesType {
  return getBrowserLanguage() || defaultLocale
}

/**
 * Read language from URL param. If not available, take browser default.
 */
export function getUrlOrPreferredLanguage(): AvailableLocalesType {
  const fromUrl = getFromUrl('language')
  return (fromUrl && filterLanguage(fromUrl)) || getPreferredLanguage()
}
