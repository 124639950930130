import { useCallback, useState } from 'react'
import {
  TerrainSpecification,
  MapStyleDataEvent,
} from 'react-map-gl'

interface MapStyleDataEventType extends MapStyleDataEvent {
  style?: {
    stylesheet?: {
      sources?: {
        terrain?: {
          type: 'raster-dem'
          url: string
          exaggeration?: number
        }
      }
    }
  }
}

export const useMapStyleTerrain = () => {

  const [terrain, setTerrain] = useState<TerrainSpecification | undefined>(undefined)

  const handleOnStyleData = useCallback(
    (event: MapStyleDataEventType) => {
      const mapStyleHasTerrain = !!event?.style?.stylesheet?.sources?.terrain
      if (mapStyleHasTerrain) {
        if (!terrain) {
          setTerrain({
            source: 'terrain',
            exaggeration: event?.style?.stylesheet?.sources?.terrain?.exaggeration || 1.8,
          })
        }
      } else {
        if (terrain) {
          setTerrain(undefined)
        }
      }
    },
    [terrain]
  )
  return {
    terrain,
    handleOnStyleData,
  }
}
