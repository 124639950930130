import { Typography } from '@mui/material'
import { useTypeLabels } from '../use-type-labels'
import { RoutePoiEntity } from '@web/shared/feature-api'

import styles from './route-poi-details.module.css'

interface RoutePoiDetailsProps {
  routePoi: RoutePoiEntity
}

export const RoutePoiDetails = ({
  routePoi,
}: RoutePoiDetailsProps) => {
  const typeLabels = useTypeLabels()

  return routePoi ? (
    <>
      {routePoi.image && (
        <img className={styles['image']} src={routePoi.image} alt={routePoi.type} />
      )}
      <Typography fontWeight={500}>
        {typeLabels[routePoi.type]}
      </Typography>
      {routePoi.description && (
        <Typography variant='body2'>
          {routePoi.description}
        </Typography>
      )}
    </>
  ) : null
}
