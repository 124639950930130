import { logError } from '@web/shared/utils-error-handling'

export type ApiSuccessResult<DataType> = {
  success: true
  data: DataType
}

export type MinimalEndpointErrors = {
  unexpectedError?: true
}

export type ApiFailureResult<ErrorsType = MinimalEndpointErrors> = {
  success: false
  errors: ErrorsType
  details?: Record<string, unknown>
}

export type ApiResult<DataType, ErrorsType = MinimalEndpointErrors> = (
  Promise<ApiSuccessResult<DataType> | ApiFailureResult<ErrorsType>>
)

export function createSuccessResult<DataType>(data: DataType): ApiSuccessResult<DataType> {
  return {
    success: true,
    data,
  }
}

export function createFailureResult<ErrorsType>(
  errors: ErrorsType,
  details?: Record<string, unknown>,
): ApiFailureResult<ErrorsType> {
  const result: ApiFailureResult<ErrorsType> = {
    success: false,
    errors,
    details,
  }
  logError('API call failed', null, result)
  return result
}
