import { useLocale, useTitle } from '@web/shared/utils-intl'
import { WidgetFallbackView } from '@web/widgets/utils-basics'

const FallbackView = () => {
  const { intl } = useLocale()
  useTitle(intl.formatMessage({
    id: 'widget_fallback_document_title',
    defaultMessage: 'Widget not found - Bikemap',
  }))

  return <WidgetFallbackView />
}

export default FallbackView
