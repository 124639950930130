import { useAuthHeader } from '@web/shared/feature-api'

const BIKEMAP_URL_IDENTIFIER = 'bikemap.net'
const MAP_STYLE_URL_IDENTIFIER = 'mapstyle.bikemap.net'
const MAPTILER_URL_IDENTIFIER = 'api.maptiler.com'
const ARCGIS_URL_IDENTIFIER = 'ibasemaps-api.arcgis.com'

type TransformRequestFunction = (url: string) => { url: string }

type UseTransformRequestReturnValues = [
  transformRequest: TransformRequestFunction,
  isAuthenticated: boolean
]

export function useTransformRequest(): UseTransformRequestReturnValues {
  const [authHeader] = useAuthHeader('')

  const transformRequest = (url: string) => {
    if (
      url.includes(BIKEMAP_URL_IDENTIFIER) &&
      !url.includes(MAP_STYLE_URL_IDENTIFIER)
    ) {
      return {
        url,
        headers: {
          Authorization: authHeader,
        },
      }
    }

    if (url.includes(MAPTILER_URL_IDENTIFIER)) {
      return {
        url: url.split('?')[0] + '?key=' + process.env['NX_MAPTILER_API_KEY'],
      }
    }

    if (url.includes(ARCGIS_URL_IDENTIFIER)) {
      return {
        url: url.split('?')[0] + '?token=' + process.env['NX_ARCGIS_API_KEY'],
      }
    }

    return {
      url,
    }
  }

  return [transformRequest, !!authHeader]
}
