import { API_URL_ROUTE } from '../config'
import { RouteBikeTypes, RouteEntity, RouteSurfaces, UserPreviewEntity } from '../entities'
import { AuthError, GoneError, addApiHeaders, getRequest, patchRequest } from '../network'
import { isValidGeometry } from '../utility'
import { ApiFailureResult, ApiResult, ApiSuccessResult, MinimalEndpointErrors, createFailureResult, createSuccessResult } from './results'
import { RouteResponse, RouteWithCollectionsResponse, convertToRouteEntity, convertToUserEntity } from '../responses'

type GetRouteDetailsErrors = MinimalEndpointErrors & {
  unexpectedResponse?: true
  invalidGeometry?: true
  unauthorized?: true
  gone?: true
}

export type GetRouteDetailsData = {
  route: RouteEntity
  creator?: UserPreviewEntity
}

/**
 * Fetch detailed info about an existing route and a preview of its creator (user).
 */
export async function getRouteDetails(
  routeId: number
): ApiResult<GetRouteDetailsData, GetRouteDetailsErrors> {
  try {
    const res = await getRequest(API_URL_ROUTE, {
      headers: await addApiHeaders(),
      params: { routeId },
      queryParams: {
        include: 'waypoints',
      },
    })
    return handleResponse(res, routeId)
  } catch (error) {
    return handleRequestError(error, routeId)
  }
}

export type GetRouteDetailsWithCollectionsData = GetRouteDetailsData & {
  collectionIds: number[]
}

/**
 * Fetch detailed info about an existing route, a preview of its creator (user) and a list of collections (IDs)
 * of the current user which the route is assigned to.
 */
export async function getRouteDetailsWithCollections(
  routeId: number
): ApiResult<GetRouteDetailsWithCollectionsData, GetRouteDetailsErrors> {
  try {
    const res: RouteWithCollectionsResponse = await getRequest(API_URL_ROUTE, {
      headers: await addApiHeaders(),
      params: { routeId },
      queryParams: {
        include: 'waypoints,routecollections',
      },
    })
    const result = handleResponse(res, routeId)
    if (result.success) {
      return createSuccessResult({
        ...result.data,
        collectionIds: res.routecollections,
      })
    }
    return result
  } catch (error) {
    return handleRequestError(error, routeId)
  }
}

export type RouteDetailsChangeForm = {
  title?: string
  description?: string
  bikeTypes?: RouteBikeTypes
  surfaces?: RouteSurfaces
  isPrivate?: boolean
}

type RouteDetailsPatchBody = {
  title?: string
  description?: string
  category?: RouteBikeTypes | [0]
  ground?: RouteSurfaces | [0]
  is_private?: boolean
}

/**
 * Set one or more attributes of a route.
 */
export async function setRouteDetails(
  routeId: number,
  { bikeTypes, surfaces, isPrivate, ...changes }: RouteDetailsChangeForm
): ApiResult<RouteEntity> {
  try {
    const body: RouteDetailsPatchBody = { ...changes }
    if (bikeTypes) {
      body.category = bikeTypes.length ? bikeTypes : [0]
    }
    if (surfaces) {
      body.ground = surfaces.length ? surfaces : [0]
    }
    if (typeof isPrivate === 'boolean') {
      body.is_private = isPrivate
    }

    const res: RouteResponse = await patchRequest(API_URL_ROUTE, {
      headers: await addApiHeaders(),
      params: { routeId },
      body,
      type: 'form-data',
    })
    return createSuccessResult(convertToRouteEntity(res))
  } catch (error) {
    return createFailureResult({ unexpectedError: true }, { routeId })
  }
}

function handleResponse(
  res: RouteResponse,
  routeId: number
): ApiSuccessResult<GetRouteDetailsData> | ApiFailureResult<GetRouteDetailsErrors> {
  if (res && res.points) {
    if (!isValidGeometry(res.points)) {
      return createFailureResult({ invalidGeometry: true }, { routeId, res })
    }

    return createSuccessResult({
      route: convertToRouteEntity(res),
      creator: res.user && res.user.slug ? convertToUserEntity(res.user) : undefined,
    })
  }
  return createFailureResult({ unexpectedResponse: true }, { routeId, res })
}

function handleRequestError(error: unknown, routeId: number): ApiFailureResult<GetRouteDetailsErrors> {
  if (error instanceof AuthError) {
    return createFailureResult({ unauthorized: true }, { routeId })
  }
  if (error instanceof GoneError) {
    return createFailureResult({ gone: true }, { routeId })
  }
  return createFailureResult({ unexpectedError: true }, { routeId })
}
