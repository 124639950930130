import React, { useEffect, useState } from 'react'
import { LngLatBoundsLike, MapProvider } from 'react-map-gl'
import { LineString } from 'geojson'
import Map, {
  MapPopup,
  MapRoute,
  MapViewport,
  NavigationControl,
  getBoundsFromGeometry,
} from '@web/shared/feature-map'
import { API_URL_DEFAULT_MAP_STYLE, RouteEntity, lngLatToGeometryCoordinates } from '@web/shared/feature-api'
import { RoutePoiContent, RoutePoisMapFeatures, useRoutePoiPopupProps } from '@web/shared/feature-route-pois'
import { ElevationCurveMapFeatures } from '@web/shared/feature-elevation-curve'
import { useWidgetContext } from '@web/widgets/utils-basics'
import { DEFAULT_VIEWPORT, MAP_ID } from '../settings'
import { useRouteWidgetParams } from '../context'

import styles from './route-widget-map.module.scss'

const MAP_ROUTE_ID = 'widget-route'

interface WidgetMapRouteProps {
  route: RouteEntity
}

const WidgetMapRoute = ({
  route,
}: WidgetMapRouteProps) => {
  const { areDistanceMarkersPermanent } = useRouteWidgetParams()
  const { isMedium } = useWidgetContext()

  return (
    <>
      <MapRoute id={MAP_ROUTE_ID} geometry={route.geometry} waypoints={route.waypoints} />
      <ElevationCurveMapFeatures
        mapRouteId={MAP_ROUTE_ID}
        geometry={route.geometry}
        distance={route.distance}
        areDistanceMarkersPermanent={areDistanceMarkersPermanent && isMedium}
      />
    </>
  )
}

interface ExtendedMapProps {
  viewport: MapViewport
  onViewportChanged: (viewport: MapViewport) => void
  geometry?: LineString
  children: React.ReactNode
}

const ExtendedMap = ({
  viewport,
  onViewportChanged,
  geometry,
  children,
}: ExtendedMapProps) => {
  const { isMedium } = useWidgetContext()

  const [boundsToFit, setBoundsToFit] = useState<undefined | LngLatBoundsLike | null>()

  useEffect(() => {
    if (boundsToFit === undefined && geometry) {
      setBoundsToFit(getBoundsFromGeometry(geometry))
    }
  }, [boundsToFit, geometry])

  const handleFitBounds = () => {
    setBoundsToFit(null)
  }

  return (
    <Map
      id={MAP_ID}
      onViewportChanged={onViewportChanged}
      mapStyle={API_URL_DEFAULT_MAP_STYLE}
      viewport={viewport}
      padding={
        isMedium ? { top: 48, bottom: 48, left: 48, right: 48 } : { top: 48, bottom: 24, left: 24, right: 24 }
      }
      boundsToFit={boundsToFit || null}
      onFitBounds={handleFitBounds}
    >
      {children}
    </Map>
  )
}

interface RouteWidgetMapProps {
  route?: RouteEntity
}

export const RouteWidgetMap = ({
  route,
}: RouteWidgetMapProps) => {
  const { isLarge } = useWidgetContext()
  const routePoiPopupProps = useRoutePoiPopupProps()

  const [viewport, setViewport] = useState<MapViewport>(DEFAULT_VIEWPORT)

  useEffect(() => {
    if (route) {
      setViewport({
        center: lngLatToGeometryCoordinates(route.waypoints[0]),
        zoom: 9,
        bearing: 0,
        pitch: 0,
      })
    }
  }, [route])

  return (
    <div className={styles['container']}>
      <MapProvider>
        <ExtendedMap
          viewport={viewport}
          onViewportChanged={setViewport}
          geometry={route?.geometry}
        >
          {route && <WidgetMapRoute route={route} />}
          {route?.hasPois && <RoutePoisMapFeatures mapId={MAP_ID} />}
          {routePoiPopupProps && (
            <MapPopup mapId={MAP_ID} {...routePoiPopupProps}>
              <RoutePoiContent />
            </MapPopup>
          )}
        </ExtendedMap>
        {isLarge && (
          <div className={styles['navigation-control']}>
            <NavigationControl mapId={MAP_ID} bearing={viewport.bearing} pitch={viewport.pitch} />
          </div>
        )}
        <div className={styles['attribution']}>
          © OpenMapTiles © OpenStreetMap contributors
        </div>
      </MapProvider>
    </div>
  )
}
