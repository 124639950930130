import React, { useState, useCallback, useMemo, useEffect } from 'react'
import maplibregl from 'maplibre-gl'
import BaseMap, { MapProps as BaseMapProps, ViewStateChangeEvent, useMap, LngLatBoundsLike } from 'react-map-gl'
import { MapViewport, VisibleMapPadding } from '../types'
import { useTransformRequest } from './use-transform-request'
import { getViewportFromViewState } from '../helpers'
import { useGlobalHeatmapLayer } from './use-global-heatmap-layer'
import { useMapStyleTerrain } from './use-map-style-terrain'

import styles from './Map.module.scss'

const MAX_DEFAULT_PITCH = 60
const MAX_3D_TERRAIN_PITCH = 72

export interface MapProps extends BaseMapProps {
  id: string
  children?: React.ReactNode
  viewport: MapViewport
  padding?: VisibleMapPadding
  boundsToFit?: LngLatBoundsLike|null
  onFitBounds?: () => void
  onViewportChanged: (viewport: MapViewport) => void
  isGlobalHeatmapEnabled?: boolean
  onGlobalHeatmapSupportDetection?: (isSupported: boolean) => void
}

export const Map = ({
  id,
  children,
  viewport,
  padding,
  boundsToFit,
  onFitBounds,
  onViewportChanged,
  isGlobalHeatmapEnabled,
  onGlobalHeatmapSupportDetection,
  ...mapProps
}: MapProps) => {
  const maps = useMap()
  const map = maps[id]

  const [transformRequest, isAuthenticated] = useTransformRequest()

  const [localViewport, setLocalViewport] = useState<MapViewport>(viewport)
  const [isViewportChanging, setIsViewportChanging] = useState<boolean>(false)
  const [lastFitBounds, setLastFitBounds] = useState<string|null>(null)
  const [isFittingToBounds, setIsFittingToBounds] = useState<boolean>(false)

  const updateGlobalHeatmapLayer = useGlobalHeatmapLayer(map, isGlobalHeatmapEnabled, onGlobalHeatmapSupportDetection)

  const { terrain, handleOnStyleData } = useMapStyleTerrain()

  const isTerrainMapStyle = useMemo(() => terrain && !isFittingToBounds, [isFittingToBounds, terrain])

  useEffect(() => {
    if (!isViewportChanging && JSON.stringify(localViewport) !== JSON.stringify(viewport)) {
      setLocalViewport(viewport)
    }
  }, [isViewportChanging, localViewport, viewport])

  useEffect(() => {
    if (
      !isViewportChanging
      && boundsToFit
      && lastFitBounds !== boundsToFit.toString()
      && map
    ) {
      map.resize() // Make sure map canvas has correct dimensions right now
      map.fitBounds(boundsToFit, { padding, duration: 1000 }, { wasFitBounds: true })
      setIsFittingToBounds(true)
    }
  }, [isViewportChanging, boundsToFit, map, lastFitBounds, padding])

  const handleMove = useCallback((event: ViewStateChangeEvent) => {
    setIsViewportChanging(true)
    setLocalViewport(getViewportFromViewState(event.viewState))
  }, [])

  const handleMoveEnd = useCallback((event: ViewStateChangeEvent) => {
    if ((event as { wasFitBounds?: boolean }).wasFitBounds) {
      setLastFitBounds(boundsToFit ? boundsToFit.toString() : null)
      onFitBounds && onFitBounds()
    } else if (lastFitBounds) {
      setLastFitBounds(null)
    }
    setIsViewportChanging(false)
    setIsFittingToBounds(false)
    onViewportChanged(getViewportFromViewState(event.viewState))
  }, [boundsToFit, lastFitBounds, onFitBounds, onViewportChanged])

  if (isAuthenticated) {
    return (
      <div className={styles['root']}>
        <BaseMap
          mapLib={maplibregl}
          id={id}
          longitude={localViewport.center[0]}
          latitude={localViewport.center[1]}
          zoom={localViewport.zoom}
          pitch={localViewport.pitch || 0}
          bearing={localViewport.bearing || 0}
          onMove={handleMove}
          onMoveEnd={handleMoveEnd}
          transformRequest={transformRequest}
          attributionControl={false}
          reuseMaps
          styleDiffing={false}
          terrain={isTerrainMapStyle ? terrain : undefined}
          maxPitch={isTerrainMapStyle ? MAX_3D_TERRAIN_PITCH : MAX_DEFAULT_PITCH}
          maxZoom={21}
          onStyleData={handleOnStyleData}
          onData={updateGlobalHeatmapLayer}
          {...mapProps}
        >
          {children}
        </BaseMap>
      </div>
    )
  }
  return null
}

export default Map
