import { useMemo } from 'react'
import { LineString, Feature } from 'geojson'
import { Layer, Source } from 'react-map-gl'
import { colors } from '@web/shared/ui-design-system'
import MapRouteLine, { MapRouteLineProps } from './map-route-line'
import { LngLat } from '../types'
import { decomposeWaypoints, getLetterFromWaypointIndex } from '../helpers'
import { useMapImage } from '../use-map-image'
import {
  ROUTE_DESTINATION_MARKER_LAYER_ID,
  ROUTE_ORIGIN_MARKER_LAYER_ID, ROUTE_VIA_MARKER_LAYER_ID,
  ROUTE_WAYPOINTS_SOURCE_ID,
} from '../settings'

import originMarkerImg from '../img/route-marker-origin.png'
import locationMarkerImg from '../img/route-marker-location.png'
import destinationMarkerImg from '../img/route-marker-destination.png'

interface MapRouteProps extends MapRouteLineProps {
  geometry: LineString
  waypoints: LngLat[]
}

/**
 * Default representation of a route on the map.
 */
export const MapRoute = ({
  geometry,
  waypoints,
  id,
  ...mapRouteLineProps
}: MapRouteProps) => {
  const { start, viaPoints, end } = useMemo(() => (decomposeWaypoints(waypoints)), [waypoints])

  useMapImage(originMarkerImg, 'route-marker-origin')
  useMapImage(locationMarkerImg, 'route-marker-location')
  useMapImage(destinationMarkerImg, 'route-marker-destination')

  return (
    <>
      <MapRouteLine id={id} {...mapRouteLineProps} geometry={geometry} />
      {start && end && (
        <Source
          id={ROUTE_WAYPOINTS_SOURCE_ID.replace('{baseId}', id)}
          type='geojson'
          data={{
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [start.lng, start.lat],
                },
                properties: { type: 'origin' },
              },
              ...viaPoints.map(({ lng, lat }, i) => ({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [lng, lat],
                },
                properties: {
                  type: 'via',
                  label: getLetterFromWaypointIndex(i),
                },
              } as Feature)),
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [end.lng, end.lat],
                },
                properties: { type: 'destination' },
              },
            ],
          }}
        >
          <Layer
            id={ROUTE_DESTINATION_MARKER_LAYER_ID.replace('{baseId}', id)}
            type='symbol'
            layout={{
              'icon-image': 'route-marker-destination',
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom',
              'icon-offset': [0, 15],
              'icon-size': 0.44,
            }}
            filter={['==', ['get', 'type'], 'destination']}
          />
          <Layer
            id={ROUTE_VIA_MARKER_LAYER_ID.replace('{baseId}', id)}
            type='symbol'
            layout={{
              'icon-image': 'route-marker-location',
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom',
              'icon-offset': [0, 13],
              'icon-size': 0.31,
              'text-field': ['get', 'label'],
              'text-font': ['Roboto Medium'],
              'text-size': 11.5,
              'text-offset': [0, -1.95],
            }}
            paint={{
              'text-color': colors.onNeutral.primary,
            }}
            filter={['==', ['get', 'type'], 'via']}
          />
          <Layer
            id={ROUTE_ORIGIN_MARKER_LAYER_ID.replace('{baseId}', id)}
            type='symbol'
            layout={{
              'icon-image': 'route-marker-origin',
              'icon-allow-overlap': true,
              'icon-anchor': 'bottom',
              'icon-offset': [0, 10],
              'icon-size': 0.47,
            }}
            filter={['==', ['get', 'type'], 'origin']}
          />
        </Source>
      )}
    </>
  )
}

export default MapRoute
