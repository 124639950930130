import React from 'react'
import clsx from 'clsx'
import { WidgetContext, useWidgetContext } from './widget-context'
import { useWidgetSize } from './use-widget-size'

import styles from './widget-container.module.scss'

interface WidgetContainerProps {
  children: React.ReactNode
}

const WidgetCard = ({
  children,
}: WidgetContainerProps) => {
  const { isMedium, isLarge } = useWidgetContext()

  return (
    <div className={clsx(styles['card'], {
      [styles['card-medium']]: isMedium,
      [styles['card-large']]: isLarge,
    })}>
      {children}
    </div>
  )
}

/**
 * Generic widget container, providing context.
 */
export const WidgetContainer = ({
  children,
}: WidgetContainerProps) => {
  const widgetSize = useWidgetSize()

  return (
    <WidgetContext.Provider value={widgetSize}>
      <WidgetCard>
        {children}
      </WidgetCard>
    </WidgetContext.Provider>
  )
}
