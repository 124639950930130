import React, { useEffect, useState } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import { isEmpty } from 'lodash'
import { useStateLanguage } from './use-state-language'
import { useUrlLanguage } from './use-url-language'
import { defaultLocale } from './config'
import { AvailableLocalesType, IntlProviderProps } from './types'

const getMessages = (language: AvailableLocalesType): Promise<object> => {
  if (language === 'de') {
    return import('@web/lang/compiled/de.json')
  }
  // No need to import 'en' since those messages are already in message definitions
  return Promise.resolve({})
}

/**
 * Can be used outside redux state provider.
 */
export function BaseIntlProvider({ children, locale = defaultLocale, loading = undefined } : IntlProviderProps) {
  const [messages, setMessages] = useState({})

  useEffect(() => {
    getMessages(locale).then(m => setMessages(m))
  }, [locale])

  const isWaitingForMessages = locale !== defaultLocale && isEmpty(messages)

  return (
    isWaitingForMessages
      ? (
        loading || null
      )
      : (
        <ReactIntlProvider defaultLocale={defaultLocale} locale={locale} messages={messages}>
          {children}
        </ReactIntlProvider>
      )
  )
}

/**
 * Needs to be wrapped in redux state provider.
 */
export function IntlProvider({ children, locale, ...rest } : IntlProviderProps) {
  useUrlLanguage()
  const preferredLanguage = useStateLanguage()
  const language = locale || preferredLanguage
  return (
    <BaseIntlProvider locale={language} {...rest}>
      {children}
    </BaseIntlProvider>
  )
}

export default IntlProvider
