import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { RouteEntity, getRouteDetails } from '@web/shared/feature-api'
import { pushRouteDataEvent } from '@web/shared/utils-analytics'
import { useLocale, useTitle } from '@web/shared/utils-intl'
import { WidgetContainer } from '@web/widgets/utils-basics'
import { RouteWidgetParamsProvider } from '../context'
import { PublicRouteWidget } from './public-route-widget'
import { RouteWidgetUnavailable } from './route-widget-unavailable'

export function RouteWidget() {
  const { intl } = useLocale()
  const { routeId } = useParams()

  const [route, setRoute] = useState<RouteEntity | undefined>()
  const [isUnavailable, setIsUnavailable] = useState<boolean | undefined>()

  useEffect(() => {
    if (routeId) {
      getRouteDetails(Number.parseInt(routeId))
        .then((res) => {
          if (res.success) {
            setRoute(res.data.route)
            setIsUnavailable(res.data.route.isPrivate)
          } else {
            setIsUnavailable(true)
          }
        })
    }
  }, [routeId])

  useEffect(() => {
    if (route?.externalId) {
      pushRouteDataEvent(route.externalId)
    }
  }, [route])

  useTitle(isUnavailable
    ? intl.formatMessage({
      id: 'route_widget_document_title_private',
      defaultMessage: 'Route not available - Bikemap Web',
    })
    : route?.title
      ? intl.formatMessage({
        id: 'route_widget_document_title_route',
        defaultMessage: '{routeTitle} - Bikemap Web',
      }, {
        routeTitle: route.title,
      })
      : intl.formatMessage({
        id: 'route_widget_document_title_loading',
        defaultMessage: 'Loading route - Bikemap Web',
      })
  )

  return (
    <WidgetContainer>
      {isUnavailable
        ? <RouteWidgetUnavailable />
        : (
          <RouteWidgetParamsProvider>
            <PublicRouteWidget route={route} />
          </RouteWidgetParamsProvider>
        )
      }
    </WidgetContainer>
  )
}

export default RouteWidget
