import { useMemo } from 'react'
import { getUrlOrPreferredLanguage } from './helpers'
import { AvailableLocalesType } from './types'

/**
 * Get language from URL param or browser default for use in local state.
 */
export function useUrlLanguageLocal(): AvailableLocalesType {
  return useMemo(
    () => getUrlOrPreferredLanguage(),
    []
  )
}
